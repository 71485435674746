/* eslint-disable prefer-template */

import React from 'react';

import { Container } from './styles';

interface userProps {
  userInfo?: {
    id: string;
    value: string;
    user_id: string;
    updated_at: string;
    user: {
      email: string;
      individual_user?: {
        name?: string;
        cpf?: string;
      };
      bank: {
        name?: string;
        agency?: string;
        account?: string;
        bank_code: {
          name: string;
        };
      };
    };
    status: {
      name?: string;
      updated_at?: string;
    };
  };
}

const HistoryCard: React.FC<userProps> = ({ userInfo }) => {
  function getFormattedDate(date: any) {
    const newDate = new Date(date);
    const str =
      newDate.getFullYear() +
      '/' +
      (newDate.getMonth() + 1) +
      '/' +
      newDate.getDate();
    return str;
  }
  return (
    <Container>
      <h2>
        Saque {userInfo?.status.name} - R$ {userInfo?.value} -
        {userInfo?.user.individual_user?.name}
      </h2>
      <h3>Informações de cadastro</h3>
      <div className="row">
        <p>{userInfo?.user.individual_user?.name}</p>
        <p>{userInfo?.user.email}</p>
        <p>{userInfo?.user.individual_user?.cpf}</p>
      </div>
      <h3>Informações de pagamento</h3>
      <div className="row">
        <p>{userInfo?.user.bank?.bank_code.name}</p>
        <p>{userInfo?.user.bank?.agency}</p>
        <p>{userInfo?.user.bank?.account}</p>
      </div>

      <h2>{getFormattedDate(userInfo?.updated_at)}</h2>
    </Container>
  );
};

export default HistoryCard;
