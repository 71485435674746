import styled, { keyframes, css } from 'styled-components';

interface MenuProps {
  menu: boolean;
}

const openMenu = keyframes`
  from {
    height: 13.41vh;
  } to {
    height: 100vh;
  }
`;

export const Container = styled.div<MenuProps>`
  position: relative;
  width: 100%;
  height: 13.41vh;
  background-color: #0000ff;
  display: flex;
  align-items: center;
  justify-content: center;

  nav {
    display: none;
  }

  .close-button {
    display: none;
  }

  img {
    height: 7.42vh;
  }

  .menu-button {
    display: none;
    background-color: transparent;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    svg {
      font-size: 40px;
      color: #ffffff;
    }

    border: none;
  }

  @media (max-width: 900px) {
    ${(props) =>
      props.menu === true
        ? css`
            animation: ${openMenu} 1s;
            position: fixed;
            top: 0;
            z-index: 1;
            flex-direction: column;
            align-items: center;
            height: 100vh;
            justify-content: space-between;
            padding: 32px 0 80px;

            .close-button {
              display: initial;
              position: absolute;
              top: 20px;
              right: 20px;
              border: none;
              background-color: transparent;

              svg {
                color: #ffffff;
                font-size: 40px;
              }
            }
            nav {
              display: flex;
              height: 60vh;
              flex-direction: column;
              align-items: center;
              justify-content: space-between;

              .avatar {
                width: 71px;
                height: 71px;
                border-radius: 100%;
                object-fit: cover;
                object-position: top;
                border: 3px solid #ffffff;
              }

              a {
                position: relative;
                span {
                  position: absolute;
                  top: 2px;
                  right: 1px;
                  width: 10px;
                  height: 10px;
                  border-radius: 100%;
                  background-color: #ffff;
                }
              }
            }
            .menu-button {
              display: none;
            }
          `
        : css`
            nav {
              display: none;
            }
            .menu-button {
              display: initial;
            }
            .close-button {
              display: none;
            }
          `}
  }
`;
