/* eslint-disable prefer-template */
import React from 'react';
import { format } from 'date-fns';

import { Container, TotalContainer, DateContainer } from './styles';

interface SolicitationsCardProps {
  orderInfo?: {
    id: string;
    total_price: string;
    created_at: string;
    user: {
      email: string;
      individual_user?: {
        name: string;
        cpf: string;
      };
    };
    order_items: [
      {
        id: string;
        name: string;
        item_url: string;
        total_price: string;
        created_at: string;
        unit_price: string;
      },
    ];
  };
}

const SolicitationsCard: React.FC<SolicitationsCardProps> = ({ orderInfo }) => {
  function getFormattedDate(date: any) {
    const newDate = format(new Date(date), "dd/MM/yyyy 'às' HH:mm");
    return newDate;
  }

  function reduceString(text: string, count: number) {
    return text.slice(0, count) + (text.length > count ? '...' : '');
  }

  function moneyMask(value: any) {
    const money = parseFloat(value).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
    return money;
  }

  return (
    <>
      <Container>
        <h1>{orderInfo?.user.individual_user?.name}</h1>
        <h5>Pedidos:</h5>
        <div className="orders">
          {orderInfo?.order_items.map(order => (
            <div>
              <p className="name">- {reduceString(order.name, 28)}</p>
              <p className="value">{moneyMask(order.unit_price)}</p>
              <a href={order.item_url} className="link">
                {reduceString(order.item_url, 30)}
              </a>
            </div>
          ))}
        </div>
        <TotalContainer>
          <h4>Total: {moneyMask(orderInfo?.total_price)}</h4>
        </TotalContainer>
        <DateContainer>
          <p>{getFormattedDate(orderInfo?.created_at)}</p>
        </DateContainer>
      </Container>
    </>
  );
};

export default SolicitationsCard;
