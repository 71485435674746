import styled from 'styled-components';

export const Container = styled.div`
  overflow-x: auto;

  table {
    display: block;
    max-width: 90%;
    margin-top: 29.5px;
    width: 100%;
    border-collapse: collapse;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
    border-radius: 24px;

    th,
    td {
      text-align: left;
      padding: 24px 16px;
    }

    thead {
      tr {
        color: #0000ff;
        font-family: Izmir-Bold;
        font-size: 1.12rem;
      }
    }

    tbody {
      td {
        white-space: nowrap;
        font-family: Izmir-Regular;
        font-size: 14px;
        button {
          background-color: #06e406;
          color: #ffffff;
          font-family: Izmir-Bold;
          font-size: 13px;
          width: 88px;
          padding: 8.5px 0;
          border: none;
          border-radius: 24px;
          transition: background-color 1s;

          &:hover {
            background-color: #1b5e20;
          }
        }
      }

      tr:nth-child(odd) {
        background-color: #f0f0f0;
      }
    }
  }
`;

export const ColumnDescription = styled.td`
  max-width: 650px;
  overflow: auto;
  white-space: break-spaces !important;

  .list__description-wrapper {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .list__description-wrapper--large {
    .list__see-more {
      display: flex;
    }
  }

  .list__description {
    overflow: hidden;

    max-height: 70px !important;
    transition: all 0.5s ease-in-out;
  }

  .list__description--expand {
    max-height: 999999px !important;
  }

  .list__see-more {
    display: none;
    justify-content: center;
    align-items: center;

    width: 80px;
    margin: 0 auto;
    padding: 2px 5px;

    color: #fff;
    font-size: 12px;

    cursor: pointer;
    background-color: blue;
    border-radius: 5px;
    box-shadow: 0 3px 6px rgb(0 0 0 /16%);
  }
`;
