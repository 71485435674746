import styled from 'styled-components';

export const Container = styled.div`
  margin-left: 184px;
  margin-right: 11.42vw;
  margin-top: 68px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 24px;

  > div {
    .pagination {
      display: table;
      margin: 10px auto;
    }
    .loading {
      display: table;
      margin: 0 auto;
    }
  }

  .users-search {
    max-width: 529px;
    width: 100%;

    h1 {
      font-size: 2.25rem;
      color: #2f78fe;
      font-family: Izmir-Bold;
      margin-bottom: 8px;
    }

    h4 {
      font-size: 1.25rem;
      font-family: Izmir-Regular;
      margin-bottom: 30.7px;
    }

    > button {
      text-decoration: none;
      border: 3px solid transparent;
      background-color: #ffffff;
      width: 100%;

      &:hover {
        border: 3px solid #06e406;
      }

      &:nth-child(even) {
        background-color: #f0f0f0;
      }

      &:last-child {
        border-bottom-left-radius: 24px;
        border-bottom-right-radius: 24px;
      }
    }
  }

  .history-link {
    margin-top: 110px;
    text-decoration: none;
    background-color: #d6e1ff;
    width: 424px;
    height: 198px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    font-size: 1.81rem;
    font-family: Izmir-Bold;
    color: #2f78fe;
    margin-left: 24px;

    img {
      margin-bottom: 19.8px;
    }
  }

  @media (max-width: 1299px) {
    margin-right: 8.4vw;
  }

  @media (max-width: 1263px) {
    margin-right: 4vw;
  }

  @media (max-width: 1069px) {
    margin: 68px auto 24px;
    flex-direction: column-reverse;
    align-items: center;

    .history-link {
      margin-top: 0;
      margin-left: 0;
      margin-bottom: 24px;
      max-width: 529px;
      width: 100%;
    }
  }

  @media (max-width: 860px) {
    .users-search,
    .history-link {
      width: 400px;
    }
  }

  @media (max-width: 442px) {
    .users-search,
    .history-link {
      width: 300px;
    }
  }
`;
