import styled from 'styled-components';

export const Container = styled.div`
  h2 {
    font-size: 1.68rem;
    font-family: Izmir-Bold;
    color: #2f78fe;
  }
`;

export const Modal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > h2 {
    font-size: 2.25rem;
    font-family: Izmir-Bold;
    color: #00a30b;
    width: 300px;
    margin-bottom: 50.5px;
  }
`;
