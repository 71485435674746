import styled from 'styled-components';

interface FormProps {
  hasError: boolean;
}

export const Container = styled.div`
  h2 {
    font-size: 1.68rem;
    font-family: Izmir-Bold;
    color: #2f78fe;
  }

  p {
    font-size: 1rem;
    font-family: Izmir-Medium;
    color: #1a1a1a;
    margin-top: 11px;
  }
`;

export const Modal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > h2 {
    font-size: 2.25rem;
    font-family: Izmir-Bold;
    color: #00a30b;
    width: 300px;
    margin-bottom: 50.5px;
  }
`;

export const Form = styled.form<FormProps>`
  > div {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    > span {
      color: red;
      font-weight: 600;
    }
  }
`;
