import styled from 'styled-components';

export const Container = styled.div`
  max-width: 505.52px;
  width: 100%;
  margin-left: 15.16vw;
  margin-top: 13.42vh;

  h1 {
    font-size: 2.37rem;
    font-family: Izmir-Bold;
    color: #2f78fe;
    margin-bottom: 28px;
  }

  p {
    font-size: 1.31rem;
    font-family: Izmir-Regular;
    max-width: 439px;
    width: 100%;
  }

  @media (max-width: 1155px) {
    margin: 13.42vh auto;
  }

  @media (max-width: 815px) {
    max-width: 56.2vw;
    p {
      max-width: initial;
    }
  }
`;

export const Card = styled.div`
  width: 100%;
  margin-top: 53.2px;
  background-color: #06e406;
  padding: 19.5px 258.52px 29.5px 35.8px;
  box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.3);
  border-radius: 24px;
  position: relative;

  h2 {
    font-size: 3.81rem;
    font-family: Izmir-Black;
    color: #ffffff;
  }

  h3 {
    max-width: 180px;
    width: 100%;
    font-family: Izmir-Medium;
    color: #ffffff;
    font-size: 1.25rem;
  }

  > div {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: 24px;
    max-width: 258.52px;
    width: 100%;
    height: 185px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 21.5px 38.8px 21.5px 37.8px;

    img {
      color: #0000ff;
      margin-top: 24px;
    }
  }

  @media (max-width: 815px) {
    height: 368px;
    padding: 19.5px 35.8px;

    h2 {
      text-align: center;
    }
    h3 {
      text-align: center;
      max-width: initial;
    }

    > div {
      top: initial;
      bottom: 0;
      max-width: initial;
      right: 0;
    }
  }
`;
