import React from 'react';
import { Container, Text } from './styles';

interface IProps {
  containerStyle: React.CSSProperties | undefined;
  textStyle?: React.CSSProperties | undefined;
  text: string;
  type?: string;
  handleClick?: () => void;
}

const Button: React.FC<IProps> = ({
  containerStyle,
  textStyle,
  text,
  type,
  handleClick,
  ...props
}) => {
  return (
    <Container style={containerStyle} onClick={handleClick} {...props}>
      <Text style={textStyle}>{text}</Text>
    </Container>
  );
};

export default Button;
