/* eslint-disable react/jsx-curly-newline */

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Skeleton } from 'antd';

interface UsersListProps {
  userList: {
    email: string;
    cashback_balance: string;
    individual_user: {
      user_id: string;
      name: string;
      cpf: string;
    };
    bank: {
      name: string;
      account: string;
      agency: string;
      bank_code: {
        name: string;
      };
    };
  };
}

function moneyMask(value: any) {
  const money = parseFloat(value).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });
  return money;
}

const Table = ({ userList }: UsersListProps) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  return (
    <tr key={userList.individual_user.user_id}>
      <td>{userList.individual_user.name}</td>
      <td>{userList.email}</td>
      <td>{userList.individual_user.cpf}</td>
      <td>{moneyMask(userList.cashback_balance)}</td>
      <td>
        {userList.bank?.bank_code.name ? userList.bank?.bank_code.name : '---'}
      </td>
      <td>{userList.bank?.agency ? userList.bank?.agency : '---'}</td>
      <td>{userList.bank?.account ? userList.bank?.account : '---'}</td>
      <td>
        <button
          type="button"
          onClick={() =>
            history.push(`cashback/${userList.individual_user.user_id}`)
          }
        >
          Acessar
        </button>
      </td>
    </tr>
  );
};

export default Table;
