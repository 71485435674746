import React, { useState, FormEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FaSpinner } from 'react-icons/fa';
import Button from '../../components/Button';
import Input from '../../components/Input';

import api from '../../services/api';

import img from '../../assets/input/user.png';
import lock from '../../assets/input/lock.png';

import logo from '../../assets/login/logo.png';
import background1 from '../../assets/login/background1.png';
import background2 from '../../assets/login/background2.png';

import { Container, Form, Row, LinkPage } from './login';

import './styles.css';

const Login: React.FC = () => {
  const dispatch = useDispatch();

  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showLoadModal, setShowLoadModal] = useState(false);
  const history = useHistory();

  async function handleSubmit(
    event: FormEvent<HTMLFormElement>,
  ): Promise<void> {
    event.preventDefault();

    if (!login || !password) {
      setErrorMessage('Preencha e-mail e senha para continuar!');
    } else {
      setShowLoadModal(true);
      setErrorMessage('');

      const usr = {
        login,
        password,
      };
      try {
        const response = await api.post('sessions', usr);
        dispatch({
          type: 'listz-adm/user/set-token',
          payload: response.data,
        });

        dispatch({
          type: 'listz-adm/user/user-info',
          payload: response.data,
        });

        history.push('/home');
      } catch (error) {
        console.error('Ops! ocorreu um erro', error.response.data.message);
        setErrorMessage(error.response.data.message);
      }

      setShowLoadModal(false);
    }
  }

  return (
    <Container>
      <Form hasError={!!errorMessage} onSubmit={handleSubmit}>
        <h1>Bem-vindo(a)!</h1>
        <Input
          containerStyle={{ marginTop: '4vh' }}
          icon={img}
          alt="User Icon"
          placeholder="E-mail ou usuário...."
          type="text"
          value={login}
          onChange={(e) => setLogin(e.target.value)}
        />
        <Input
          containerStyle={{ marginTop: '1.2vh' }}
          icon={lock}
          alt="Lock Icon"
          placeholder="Senha...."
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button
          containerStyle={{ marginTop: '4vh' }}
          text="Entrar"
          type="submit"
        />
        {showLoadModal ? <FaSpinner size={30} className="icon-spin" /> : ''}
        {errorMessage && <span>{errorMessage}</span>}
        <LinkPage to="/forgotPassword">Esqueci minha senha</LinkPage>
        <Row>
          <p>Você é novo aqui?</p>
          <p>
            <LinkPage to="/signup">Cadastre-se</LinkPage>
          </p>
        </Row>
      </Form>
      <img src={logo} className="logo" alt="logo" />
      <img src={background1} className="background1" alt="image1" />
      <img src={background2} className="background2" alt="image2" />
    </Container>
  );
};

export default Login;
