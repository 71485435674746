import styled from 'styled-components';

export const Container = styled.div`
  max-width: 854px;
  background-color: #f1f1f1;
  border-radius: 24px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  > div {
    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      p {
        margin-bottom: 0;
      }

      .name {
        width: 280px;
      }

      .link {
        width: 313px;
      }

      @media (max-width: 1042px) {
        flex-direction: column;

        .name,
        .value,
        .link {
          text-align: center;
        }
      }

      .value {
        width: 100px;
        font-weight: bold;
        text-align: left;

        @media (max-width: 1006px) {
          margin-left: 0;
        }
      }
    }
  }

  .orders {
    overflow-x: hidden;
    max-height: 350px;
  }

  h1,
  h5 {
    color: #1511f9;
  }

  h5 {
    font-weight: bold;
  }

  ul {
    list-style: none;
  }

  @media (max-width: 470px) {
    h1,
    h5 {
      text-align: center;
    }

    h5 {
      margin-bottom: 20px;
    }
  }
`;

export const TotalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: bold;
  margin-top: 40px;
`;

export const DateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: bold;
`;
