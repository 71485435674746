import React from 'react';
import { HashRouter, Switch } from 'react-router-dom';
import Route from './Route';
import Login from '../pages/login';
import Signup from '../pages/Signup';
import ForgotPassword from '../pages/ForgotPassword';
import PasswordChange from '../pages/PasswordChange';
import Home from '../pages/Home';
import Profile from '../pages/Profile';
import EditEmail from '../pages/Profile/EditEmail';
import ResetPassword from '../pages/Profile/ResetPassword';
import Users from '../pages/Users';
import Notification from '../pages/Notification';
import ListCreate from '../pages/ListCreate';
import MyLists from '../pages/MyLists';
import CategoriesCreate from '../pages/CategoriesCreate';
import EditLists from '../pages/EditLists';
import History from '../pages/History';
import Solicitations from '../pages/Solicitations';
import Cashback from '../pages/Cashback';
import UserSelected from '../pages/Cashback/UserSelected';
import SolicitationsHistory from '../pages/SolicitationsHistory';

const Routes: React.FC = () => (
  <HashRouter>
    <Switch>
      <Route path="/" exact component={Login} />
      <Route path="/signup" component={Signup} />
      <Route path="/forgotPassword" component={ForgotPassword} />
      <Route path="/alterar-senha" component={PasswordChange} />
      <Route path="/home" component={Home} isPrivate />
      <Route path="/profile" exact component={Profile} isPrivate />
      <Route path="/profile/edit-email" component={EditEmail} isPrivate />
      <Route
        path="/profile/reset-password"
        component={ResetPassword}
        isPrivate
      />
      <Route path="/users" component={Users} isPrivate />
      <Route path="/cashback" exact component={Cashback} isPrivate />
      <Route path="/cashback/:userId" component={UserSelected} isPrivate />
      <Route path="/notification" component={Notification} isPrivate />
      <Route path="/criar-listas" component={ListCreate} isPrivate />
      <Route path="/minhas-listas" component={MyLists} isPrivate />
      <Route path="/criar-categorias" component={CategoriesCreate} isPrivate />
      <Route path="/editar-listas" component={EditLists} isPrivate />
      <Route
        path="/solicitations/:userName"
        component={Solicitations}
        isPrivate
      />
      <Route path="/history/:userName" component={History} isPrivate />
      <Route
        path="/historico-pedidos/"
        component={SolicitationsHistory}
        isPrivate
      />
    </Switch>
  </HashRouter>
);

export default Routes;
