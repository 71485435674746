import styled from 'styled-components';

export const Container = styled.div`
  > img:nth-of-type(1) {
    position: absolute;
    height: 103px;
    top: 0;
    left: 10.24vw;
  }

  > img:nth-of-type(2) {
    position: absolute;
    height: 640.53px;
    left: 20px;
    bottom: 0;
  }

  > img:nth-of-type(3) {
    position: absolute;
    height: 289px;
    top: 0;
    right: 0;
  }

  @media (max-width: 1250px) {
    > img:nth-of-type(2) {
      height: 400px;
    }
  }

  @media (max-width: 1010px) {
    > img:nth-of-type(1) {
      left: 50%;
      transform: translateX(-50%);
    }
    > img:nth-of-type(2) {
      display: none;
    }
    > img:nth-of-type(3) {
      display: none;
    }
  }
`;

export const Form = styled.form`
  position: absolute;
  top: 2vh;
  right: 1vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4.5px 39.5px 76.1px;
  width: 471px;
  height: 630px;
  border-radius: 24px;
  z-index: 1;
  background-color: #fff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3);
  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    > h1 {
      font-family: Izmir-Bold;
      color: #2f78fe;
      margin-bottom: 2px;
      font-size: 2.37rem;
      font-weight: bold;
    }

    > p {
      font-family: Izmir-Regular;
      color: #000000;
      font-size: 2.4rem;
    }

    > span {
      font-family: Izmir-Regular;
      font-size: 1.06rem;
      margin-top: 3vh;
      color: #c5c5c5;
    }
  }

  > span {
    color: red;
    margin-top: 10px;
    font-weight: 600;
  }

  @media (max-width: 1010px) {
    right: 0px;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    top: 50%;
    max-width: 530px;
    width: 100%;
    box-shadow: 0px 0px 0px 0px;
    margin-top: 20px;
  }

  @media (max-width: 33.125rem) {
    width: 100%;
  }
`;
