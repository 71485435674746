import styled from 'styled-components';

export const Container = styled.section`
  padding: 34px 46px;
  display: flex;
  flex-direction: column;

  h3 {
    align-self: flex-start;
    font-family: Izmir-Medium;
    font-size: 1.06rem;
    color: #0000ff;
    margin-bottom: 8px;
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    p {
      font-family: Izmir-Regular;
      font-size: 1rem;
      color: #000000;

      & + p {
        margin-left: 16px;
      }
    }
  }

  @media (max-width: 860px) {
    .row {
      flex-direction: column;
      align-items: flex-start;
      justify-content: initial;

      p {
        & + p {
          margin-left: 16px;
        }
      }
    }
  }
`;
