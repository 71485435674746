import React, { useState, useEffect, useMemo } from 'react';
import socketio from 'socket.io-client';

import { Link } from 'react-router-dom';
import api from '../../services/api';

import homeImg from '../../assets/verticalTab/home.png';
import moneyImg from '../../assets/verticalTab/cash.png';
import megaphone from '../../assets/verticalTab/megaphone.png';
import notificationImg from '../../assets/verticalTab/notification.png';
import plusImg from '../../assets/verticalTab/plus.png';
import fallbackImg from '../../assets/verticalTab/fallbackImg.jpg';
import pedidosIcon from '../../assets/verticalTab/pedidosIcon.png';

import { Container } from './styles';

interface notificationsInterface {
  read: Boolean;
  content: string;
}

const VerticalTab: React.FC = () => {
  const [newNotification, setNewNotification] = useState(true);
  const [userImg, setUserImg] = useState();
  const [notifications, setNotifications] = useState<notificationsInterface[]>(
    [],
  );

  const { UserInfo, User } = JSON.parse(
    sessionStorage.getItem('ListzADM@UserAdmin') || '{}',
  );

  const socket = useMemo(
    () =>
      socketio('https://api.listz.com.br', {
        query: {
          user_id: UserInfo.userInfo.id,
        },
      }),
    [UserInfo.userInfo.id],
  );

  useEffect(() => {
    socket.on('notification', (notification: any) => {
      setNotifications([notification, ...notifications]);
    });
  }, [socket, notifications]);

  useEffect(() => {
    async function getUserInfo() {
      const headers = { Authorization: `Bearer ${User.token}` };
      const response = await api.get(`users/${UserInfo.userInfo.id}`, {
        headers,
      });
      setUserImg(response.data.avatar_url);
    }

    async function notificationsIndex() {
      try {
        const headers = { Authorization: `Bearer ${User.token}` };
        const response = await api.get('/notifications', { headers });
        setNotifications(response.data.results);
      } catch (error) {
        console.log('');
      }
    }
    notificationsIndex();
    getUserInfo();
  }, []);

  useEffect(() => {
    let found = false;
    for (let i = 0; i < notifications.length; i += 1) {
      if (notifications[i].read === false) {
        found = true;
        break;
      }
    }

    setNewNotification(found);
  }, [notifications]);

  function renderNotification() {
    if (newNotification) {
      return <span />;
    }
  }

  return (
    <Container>
      <Link to="/profile">
        <img
          className="avatar"
          src={userImg || fallbackImg}
          onError={(e: any) => {
            e.target.src = fallbackImg;
          }}
          alt="avatar"
        />
      </Link>

      <Link to="/home">
        <img style={{ width: '49px' }} src={homeImg} alt="home-icon" />
      </Link>

      <Link to="/cashback">
        <img style={{ width: '40.4px' }} src={moneyImg} alt="money-icon" />
      </Link>

      <Link to="/solicitations/:userName">
        <img style={{ width: '40.4px' }} src={megaphone} alt="money-icon" />
      </Link>

      <Link to="/criar-listas">
        <img style={{ width: '30.57px' }} src={plusImg} alt="plus-icon" />
      </Link>
      <Link to="/historico-pedidos">
        <img style={{ width: '30.57px' }} src={pedidosIcon} alt="plus-icon" />
      </Link>
      <Link to="/notification">
        <img
          style={{ width: '30.57px', height: 'auto' }}
          src={notificationImg}
          alt="notification-icon"
        />
        {renderNotification()}
      </Link>
    </Container>
  );
};

export default VerticalTab;
