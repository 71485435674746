import styled from 'styled-components';
import { Link } from 'react-router-dom';

interface FormProps {
  hasError: boolean;
}

export const Container = styled.div`
  > img:nth-of-type(1) {
    position: absolute;
    height: 103px;
    top: 0vh;
    left: 10.24vw;
  }
  > img:nth-of-type(2) {
    position: absolute;
    height: 240px;
    left: 0px;
    bottom: 0px;
  }
  > img:nth-of-type(3) {
    position: absolute;
    height: 600px;
    bottom: 0px;
    right: 0px;
  }
  @media (max-width: 1294px) {
    > img:nth-of-type(3) {
      height: 400px;
    }
  }
  @media (max-width: 1022px) {
    display: flex;
    align-items: center;
    justify-content: center;
    > img:nth-of-type(1) {
      left: 50%;
      transform: translateX(-50%);
    }
    > img:nth-of-type(3) {
      display: none;
    }
  }
  @media (max-width: 595px) {
    > img:nth-of-type(2) {
      height: 160px;
    }
  }

  @media (max-width: 441px) {
    padding-left: 8px;
    padding-right: 8px;

    > img:nth-of-type(2) {
      display: none;
    }
  }
`;

export const Form = styled.form<FormProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 406px;
  height: 503px;
  padding: 63px 36px 48px 32px;
  border-radius: 24px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3);
  flex-shrink: 0;
  z-index: 1;
  margin-top: 20vh;
  margin-left: 10.24vw;
  h1 {
    font-size: 2.06rem;
    font-family: Izmir-Bold;
    color: #2f78fe;
  }
  p {
    font-family: Izmir-Regular;
    font-size: 1.06rem;
    margin-top: 5.1vh;
    color: #c5c5c5;
  }

  > svg {
    margin-top: 10px;
  }

  > span {
    color: red;
    margin-top: 10px;
    font-weight: 600;
  }

  @media (max-width: 1022px) {
    margin-top: 18vh;
    margin-left: 0px;
  }
  @media (max-width: 32.5rem) {
    text-align: center;
    width: 100%;
  }
`;

export const Row = styled.div`
  display: flex;
  margin-top: 2vh;

  > p {
    color: #000000;
    font-size: 1.31rem;
    margin-top: 13px;

    a {
      font-family: 'Izmir-Bold';
      font-size: 1.31rem;
      color: #2f78fe;
      margin-left: 4px;
    }
  }
`;

export const LinkPage = styled(Link)`
  text-decoration: none;
  color: inherit;
`;
