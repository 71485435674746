import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Container } from './styles';
import api from '../../../services/api';

interface notificationsParam {
  notificationsInfo?: {
    id: string;
    content: string;
    read: Boolean;
    created_at: string;
  };
  setHideButton?: any;
}

const NotifcationCard: React.FC<notificationsParam> = ({
  notificationsInfo,
  setHideButton,
}) => {
  const history = useHistory();

  const { User } = JSON.parse(
    sessionStorage.getItem('ListzADM@UserAdmin') || '{}',
  );
  async function handleRead(id?: String) {
    try {
      const headers = { Authorization: `Bearer ${User.token}` };
      await api.put(
        `/notifications/${id}/read`,
        {},
        {
          headers,
        },
      );
      setHideButton(true);
    } catch (error) {
      console.log('');
    }
  }

  return (
    <Container>
      <h2>{notificationsInfo?.content}</h2>
      <div>
        {/*
          <button
          type="button"
          onClick={() => history.push(`/cashback-solicitations/`)}
        >
          Ver solicitação
        </button>
        */}
        {!notificationsInfo?.read ? (
          <button
            type="button"
            onClick={() => handleRead(notificationsInfo?.id)}
          >
            Marcar como lido
          </button>
        ) : (
          ''
        )}
      </div>
    </Container>
  );
};

export default NotifcationCard;
