/* eslint-disable react/jsx-curly-newline */

import React, { useState } from 'react';
import { Link, useRouteMatch, useHistory } from 'react-router-dom';
import api from '../../../../services/api';
import ModalWrap from '../../../../components/ModalWrap';

import { ModalError, Modal } from './styles';

interface RouteParam {
  userId: string;
}

interface UsersListProps {
  userPendingCashback: {
    id: string;
    value: string;
    user: {
      cashback_balance: string;
      pending_cashback: string;
    };
  };
  loadingPendingCashback: boolean;
  setLoadingPendingCashback: any;
}

const Table = ({
  userPendingCashback,
  loadingPendingCashback,
  setLoadingPendingCashback,
}: UsersListProps) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [errorModal, setErrorModal] = useState(false);
  const [isModal, setIsModal] = useState(false);

  async function handleReleaseCashback() {
    const { User } = JSON.parse(
      sessionStorage.getItem('ListzADM@UserAdmin') || '{}',
    );

    const headers = { Authorization: `Bearer ${User.token}` };

    try {
      await api.patch(`cashbacks/${userPendingCashback.id}/release`, null, {
        headers,
      });
      setLoadingPendingCashback(!loadingPendingCashback);
      setIsModal(true);
    } catch (error) {
      setErrorMessage(error.response.data.message);
      setErrorModal(true);
    }
  }

  function renderModal() {
    if (isModal) {
      return (
        <ModalWrap toggleModal={() => setIsModal(false)}>
          <Modal>
            <h2>Casback liberado com sucesso!</h2>
          </Modal>
        </ModalWrap>
      );
    }
  }

  function renderErrorModal() {
    if (errorModal) {
      return (
        <ModalWrap toggleModal={() => setErrorModal(false)}>
          <ModalError>
            <h2>{errorMessage}</h2>
          </ModalError>
        </ModalWrap>
      );
    }
  }

  function moneyMask(value: any) {
    const money = parseFloat(value).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
    return money;
  }

  return (
    <>
      <tr>
        <td>
          {moneyMask(userPendingCashback.value) &&
            moneyMask(userPendingCashback.value)}
        </td>
        <td>
          <button type="button" onClick={() => handleReleaseCashback()}>
            Liberar cashback
          </button>
        </td>
      </tr>
      {renderModal()}
      {renderErrorModal()}
    </>
  );
};

export default Table;
