import React, { ChangeEvent, useState, useEffect } from 'react';
import { Link, useRouteMatch, useHistory } from 'react-router-dom';
import { Pagination, Skeleton } from 'antd';
import IntlCurrencyInput from 'react-intl-currency-input';
import api from '../../../services/api';

import LayoutWithoutImage from '../../../components/LayoutWithoutImage';
import SearchInput from '../../../components/SearchInput';
import Input from '../../../components/Input';
import ModalWrap from '../../../components/ModalWrap';
import Card from '../Card';
import Table from './Table';

import SuccessImg from '../../../assets/modalWrap/success.png';

import {
  Container,
  Modal,
  ModalError,
  TableContainer,
  PendingCashback,
} from './styles';

interface RouteParam {
  userId: string;
}

interface UserData {
  email: string;
  individual_user: {
    name: string;
    cpf: string;
  };
}

interface UserPendingCashback {
  id: string;
  value: string;
  user: {
    pending_cashback: string;
    cashback_balance: string;
  };
}

const currencyConfig = {
  locale: 'pt-BR',
  formats: {
    number: {
      BRL: {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

const UserSelected: React.FC = () => {
  const [searchValue, setSearchValue] = useState('');
  const [totalCashAvailable, setTotalCashAVailable] = useState();
  const [date, setDate] = useState('');
  const [cashbackAmount, setCashbackAmount] = useState('');
  const [isModal, setIsModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [user, setUser] = useState<UserData>();
  const [usersListPendingCashback, setUsersListPendingCashback] = useState<
    UserPendingCashback[]
  >([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(10);
  const [loading, setLoading] = useState(false);
  const [loadingPendingCashback, setLoadingPendingCashback] = useState(false);

  const { params } = useRouteMatch<RouteParam>();

  const history = useHistory();

  useEffect(() => {
    async function getUsersCashbacks() {
      const { User } = JSON.parse(
        sessionStorage.getItem('ListzADM@UserAdmin') || '{}',
      );
      const headers = { Authorization: `Bearer ${User.token}` };
      const resp = await api.get(`users/${params.userId}`, { headers });
      setUser(resp.data);
      setLoading(true);
    }
    getUsersCashbacks();
  }, []);

  useEffect(() => {
    async function getUserPendingCashback() {
      const { User } = JSON.parse(
        sessionStorage.getItem('ListzADM@UserAdmin') || '{}',
      );
      const headers = { Authorization: `Bearer ${User.token}` };
      const resp = await api.get(`users/${params.userId}/pending-cashbacks`, {
        headers,
      });
      setUsersListPendingCashback(resp.data.results);
      setPage(resp.data.page);
      setTotal(resp.data.total);
      setLoading(true);
    }

    getUserPendingCashback();
  }, [loadingPendingCashback]);

  function handleChangePage(e: any) {
    const { User } = JSON.parse(
      sessionStorage.getItem('ListzADM@UserAdmin') || '{}',
    );
    const headers = { Authorization: `Bearer ${User.token}` };
    api
      .get(`/users/${params.userId}/pending-cashbacks?page=${e}&limit=8`, {
        headers,
      })
      .then((resp) => {
        setUsersListPendingCashback(resp.data.results);
      });
  }

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  function renderCardTitle() {
    if (!!totalCashAvailable && !!date && !!cashbackAmount) {
      return <h5>Carlos Henrique gostaria de sacar R$ {cashbackAmount},00</h5>;
    }
  }

  async function handleReleaseWithdraw() {
    const { User } = JSON.parse(
      sessionStorage.getItem('ListzADM@UserAdmin') || '{}',
    );
    const usr = {
      user_id: params.userId,
      value: totalCashAvailable,
    };

    const headers = { Authorization: `Bearer ${User.token}` };

    try {
      await api.post(`cashbacks`, usr, { headers });
      setIsModal(true);
      setLoadingPendingCashback(!loadingPendingCashback);
    } catch (error) {
      console.log('error', error);
      setErrorMessage(error.response.data.message);
      setErrorModal(true);
    }
  }

  function renderWithdrawButton() {
    if (totalCashAvailable) {
      return (
        <button type="button" onClick={handleReleaseWithdraw}>
          Gerar Cashback
        </button>
      );
    }
  }

  function renderModal() {
    if (isModal) {
      return (
        <ModalWrap toggleModal={() => setIsModal(false)}>
          <Modal>
            <h2>Casback gerado com sucesso!</h2>
            <p>
              Para {user?.individual_user.name}&nbsp;no valor de&nbsp; R$
              {totalCashAvailable}
            </p>
            <img src={SuccessImg} alt="sucesso" />
            <Link to={`/history/${user?.individual_user.name}`}>
              Ver histórico
            </Link>
          </Modal>
        </ModalWrap>
      );
    }
  }

  function renderErrorModal() {
    if (errorModal) {
      return (
        <ModalWrap toggleModal={() => setErrorModal(false)}>
          <ModalError>
            <h2>{errorMessage}</h2>
          </ModalError>
        </ModalWrap>
      );
    }
  }

  const handleChangeWithDraw = (event: any, value: any, maskedValue: any) => {
    event.preventDefault();
    console.log('value', value);
    setTotalCashAVailable(value);
  };

  function moneyMask(value: any) {
    const money = parseFloat(value).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
    return money;
  }

  return (
    <LayoutWithoutImage>
      <Container disabled={!totalCashAvailable}>
        <div>
          <div className="users-search">
            <h1>Cashback</h1>
            <h4>Selecione o Usuário para inserir o valor do Cashback</h4>
            <SearchInput
              value={searchValue}
              onClick={() => history.push(`/cashback/${searchValue}`)}
              onChange={handleSearch}
              containerStyle={{ marginBottom: '36.1px' }}
            />
            <h2>Qual é o valor do Cashback?</h2>
            <h4>Insira o valor do Cashback para este usuário.</h4>
            <IntlCurrencyInput
              className="withdrawInput"
              currency="BRL"
              config={currencyConfig}
              onChange={handleChangeWithDraw}
            />
          </div>
          <div className="right-card">
            {loading ? (
              <>
                <Card userInfo={user}>{renderCardTitle()}</Card>
                {renderWithdrawButton()}
              </>
            ) : (
              'Carregando...'
            )}
          </div>
        </div>
        <PendingCashback>
          <h5>Cashback Pendente:</h5>
          {usersListPendingCashback.length ? (
            <h4>
              {moneyMask(usersListPendingCashback[0]?.user?.pending_cashback)}
            </h4>
          ) : (
            <h4>R$ 0</h4>
          )}
        </PendingCashback>
        <TableContainer>
          <table>
            {loading ? (
              <>
                <thead>
                  <tr>
                    <th>Cashback</th>
                  </tr>
                </thead>
                <tbody>
                  {usersListPendingCashback &&
                    usersListPendingCashback.map((pendingCashback) => {
                      return (
                        <Table
                          loadingPendingCashback={loadingPendingCashback}
                          setLoadingPendingCashback={setLoadingPendingCashback}
                          userPendingCashback={pendingCashback}
                        />
                      );
                    })}
                </tbody>
              </>
            ) : (
              <Skeleton className="skeleton" active />
            )}
          </table>
          <div className="pagination">
            <Pagination
              defaultCurrent={page}
              total={total}
              onChange={(e) => handleChangePage(e)}
            />
          </div>
        </TableContainer>
      </Container>
      {renderModal()}
      {renderErrorModal()}
    </LayoutWithoutImage>
  );
};

export default UserSelected;
