import React, { useState, FormEvent } from 'react';
import { FaSpinner } from 'react-icons/fa';
import api from '../../../services/api';

import Button from '../../../components/Button';
import Input from '../../../components/Input';
import ProfileLayout from '../ProfileLayout';
import ModalWrap from '../../../components/ModalWrap';

import LockIcon from '../../../assets/input/lock.png';
import SuccessImg from '../../../assets/modalWrap/success.png';

import { Container, Form, Modal } from './styles';

import './styles.css';

const ResetPassword: React.FC = () => {
  const [isModal, setIsModal] = useState(false);
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showLoadModal, setShowLoadModal] = useState(false);

  const { User, UserInfo } = JSON.parse(
    sessionStorage.getItem('ListzADM@UserAdmin') || '{}',
  );

  async function handleSubmit(
    event: FormEvent<HTMLFormElement>,
  ): Promise<void> {
    event.preventDefault();

    if (!password || !newPassword || !oldPassword) {
      setErrorMessage('Preencha todos os campos para continuar!');
    } else {
      setErrorMessage('');
      setShowLoadModal(true);

      const usr = {
        password,
        password_confirmation: newPassword,
        old_password: oldPassword,
      };

      try {
        const headers = { Authorization: `Bearer ${User.token}` };
        await api.put(`/users/${UserInfo.userInfo.id}`, usr, { headers });
        setIsModal(true);
      } catch (error) {
        console.error('Ops! ocorreu um erro', error.response.data.message);
        setErrorMessage(error.response.data.message);
      }
      setShowLoadModal(false);
    }
  }

  function renderModal() {
    if (isModal) {
      return (
        <ModalWrap toggleModal={() => setIsModal(false)}>
          <Modal>
            <h2>Senha alterada com sucesso!</h2>
            <img src={SuccessImg} alt="sucesso" />
          </Modal>
        </ModalWrap>
      );
    }
  }
  return (
    <>
      <ProfileLayout>
        <Container>
          <Form hasError={!!errorMessage} onSubmit={handleSubmit}>
            <h2>Redefinir Senha</h2>
            <p>Senha Antiga</p>
            <Input
              icon={LockIcon}
              type="text"
              containerStyle={{ marginTop: '11.5px', marginBottom: '23.4px' }}
              value={oldPassword}
              onChange={(e) => {
                setOldPassword(e.target.value);
              }}
            />
            <p>Nova Senha</p>
            <Input
              icon={LockIcon}
              type="text"
              containerStyle={{ marginTop: '11.5px', marginBottom: '23.4px' }}
              value={newPassword}
              onChange={(e) => {
                setNewPassword(e.target.value);
              }}
            />
            <p>Confirmar Senha</p>
            <Input
              icon={LockIcon}
              type="text"
              containerStyle={{ marginTop: '11.5px' }}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            <Button
              containerStyle={{ marginTop: '23.4px', marginBottom: '34.4px' }}
              text="Alterar senha"
              type="submit"
            />
            <div>
              {showLoadModal ? (
                <FaSpinner size={30} className="icon-spin" />
              ) : (
                ''
              )}
              {errorMessage && <span>{errorMessage}</span>}
            </div>
          </Form>
        </Container>
      </ProfileLayout>
      {renderModal()}
    </>
  );
};

export default ResetPassword;
