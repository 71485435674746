import React, { ChangeEvent, useState, useEffect } from 'react';
import { Link, useRouteMatch, useHistory } from 'react-router-dom';
import { Pagination, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import api from '../../services/api';

import LayoutWithoutImage from '../../components/LayoutWithoutImage';
import SearchInput from '../../components/SearchInput';
import CardSelected from './CardSelected';

import CashbackImg from '../../assets/cashback/cashback.png';

import { Container } from './styles';

const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

interface RouteParam {
  userId: string;
}
interface UserData {
  id: string;
  value: string;
  agency: string;
  account: string;
  email: string;
  individual_user?: {
    name: string;
    cpf: string;
  };
  bank: {
    name: string;
    agency: string;
    account: string;
    bank_code: {
      name: string;
    };
  };
  status: {
    name: string;
    updated_at: string;
  };
}

const Cashback: React.FC = () => {
  const [searchValue, setSearchValue] = useState('');
  const [user, setUser] = useState<UserData[]>([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(10);
  const [loading, setLoading] = useState(false);

  const { params } = useRouteMatch<RouteParam>();

  const history = useHistory();

  useEffect(() => {
    async function getUsersCashbacks() {
      const { User } = JSON.parse(
        sessionStorage.getItem('ListzADM@UserAdmin') || '{}',
      );
      const headers = { Authorization: `Bearer ${User.token}` };
      const resp = await api.get(`users?name=${searchValue}&limit=5`, {
        headers,
      });
      setUser(resp.data.results);
      setPage(resp.data.page);
      setTotal(resp.data.total);
      setLoading(true);
    }
    getUsersCashbacks();
  }, []);

  async function handleUsersSearch(): Promise<any> {
    const { User } = JSON.parse(
      sessionStorage.getItem('ListzADM@UserAdmin') || '{}',
    );
    const headers = { Authorization: `Bearer ${User.token}` };
    const resp = await api.get(`users?name=${searchValue}`, { headers });
    setUser(resp.data.results);
  }

  function handleChangePage(e: any) {
    const { User } = JSON.parse(
      sessionStorage.getItem('ListzADM@UserAdmin') || '{}',
    );
    const headers = { Authorization: `Bearer ${User.token}` };
    api
      .get(`users?name=${searchValue}&page=${e}&limit=5`, { headers })
      .then(response => {
        setUser(response.data.results);
      });
  }

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };
  return (
    <LayoutWithoutImage>
      <Container>
        <div className="users-search">
          <h1>Cashback</h1>
          <h4>Selecione o Usuário para inserir o valor do Cashback</h4>
          <SearchInput
            value={searchValue}
            onChange={handleSearch}
            handleUsersSearch={handleUsersSearch}
          />
          {loading ? (
            <>
              {user.map(result => (
                <button
                  type="button"
                  onClick={() => history.push(`/cashback/${result.id}`)}
                >
                  <CardSelected userInfo={result} />
                </button>
              ))}
            </>
          ) : (
            <div className="loading">
              <Spin indicator={antIcon} />
            </div>
          )}
          <div className="pagination">
            <Pagination
              defaultCurrent={page}
              total={total}
              onChange={e => handleChangePage(e)}
            />
          </div>
        </div>
        <Link to="/history/:userName" className="history-link">
          <img src={CashbackImg} alt="cashback" />
          <h2>Ver Histórico de cashback</h2>
        </Link>
      </Container>
    </LayoutWithoutImage>
  );
};

export default Cashback;
