import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import api from '../../services/api';

import Button from '../../components/Button';
import LayoutWithImage from '../../components/LayoutWithImage';
import GraphicImg from '../../assets/home/graphic.png';

import { Container, Card } from './styles';

const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

const Home: React.FC = () => {
  const [users, setUsers] = useState(0);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const history = useHistory();

  function handleLogout() {
    dispatch({ type: 'listz-adm/user/logout' });
    history.push('/');
  }

  useEffect(() => {
    async function getUsers() {
      const { User } = JSON.parse(
        sessionStorage.getItem('ListzADM@UserAdmin') || '{}',
      );
      const headers = { Authorization: `Bearer ${User.token}` };
      const resp = await api.get(`users`, { headers });
      setUsers(resp.data.total);
      setLoading(false);
    }
    getUsers();
  }, []);

  return (
    <LayoutWithImage>
      <Container>
        <h1>Bem vindo(a)!</h1>
        <p>
          Nesta plataforma você terá acesso aos dados de cadastro dos usuários
          abaixo e informações de Cashback e notificações na barra lateral
        </p>

        <Card>
          <h2>{loading ? <Spin indicator={antIcon} /> : users}</h2>
          <h3>Número de usuários clientes</h3>
          <div>
            <img src={GraphicImg} alt="gráfico" />
            <Button
              text="Acesso aos usuários"
              containerStyle={{ borderRadius: '24px', marginTop: '24px' }}
              textStyle={{ fontSize: '1rem' }}
              handleClick={() => history.push('/users')}
            />
          </div>
        </Card>
      </Container>
      <Button
        text="SAIR"
        containerStyle={{ borderRadius: '24px', marginTop: '24px' }}
        handleClick={() => handleLogout()}
      />
    </LayoutWithImage>
  );
};

export default Home;
