import styled from 'styled-components';

export const Container = styled.div`
  margin-left: 184px;
  margin-right: 11.42vw;
  margin-top: 68px;
  margin-bottom: 24px;

  h1 {
    font-size: 2.25rem;
    color: #2f78fe;
    font-family: Izmir-Bold;
    margin-bottom: 26px;
  }

  .loading {
    display: table;
    margin: 0 auto;
  }

  @media (max-width: 700px) {
    margin: 68px auto;
    max-width: 500px;
  }

  @media (max-width: 540px) {
    max-width: 90vw;
  }
`;
