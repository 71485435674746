import styled from 'styled-components';

export const Container = styled.div`
  margin: 68px;
  margin-right: 11.42vw;
  margin-bottom: 24px;
  margin-left: 184px;

  h1 {
    font-size: 2.25rem;
    color: #2f78fe;
    font-family: Izmir-Bold;
    margin-bottom: 26px;
  }

  .input-direction {
    margin-top: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 51px;

    .input-search {
      max-width: 38.74vw;
      width: 100%;
    }

    .input-date {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .day-input {
        width: 100.78px;
        height: 54.5px;
        padding: 0 16px;
        font-family: Izmir-Regular;
        font-size: 1.18rem;
        color: #979797;
        background-color: #f5f5f5;
        border-radius: 16px;
        border: none;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
        margin-left: 16px;
      }

      select {
        border: none;
        background-color: #f5f5f5;
        font-size: 1.18rem;
        font-family: Izmir-Regular;
        color: #979797;
        height: 54.5px;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
        border-radius: 16px;
        padding: 16px;
        margin-left: 16px;
      }

      button {
        background-color: #1511f9;
        width: 55.72px;
        height: 54.5px;
        padding: 0 16px;
        border: none;
        margin-left: 16px;
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: background-color 1s;

        &:hover {
          background-color: #1a237e;
        }
      }
    }
  }

  .pagination {
    max-width: 300px;
    width: 100%;
    margin: 16px auto;
    display: flex;
    align-items: center;
    justify-content: center;

    > a {
      font-size: 1.2rem;
      padding: 16px;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .loading {
    display: table;
    margin: 0 auto;
  }

  @media (max-width: 910px) {
    .input-direction {
      flex-direction: column;
      align-items: center;

      .input-search {
        max-width: 450px;
      }
      .input-date {
        margin-top: 24px;
        .day-input {
          margin-left: 0;
        }
      }
    }
  }

  @media (max-width: 690px) {
    margin: 68px auto 24px;
    padding: 0 16px;
  }

  @media (max-width: 460px) {
    .input-direction {
      .input-date {
        .day-input {
          max-width: 21.9vw;
        }
        select {
          max-width: 21.9vw;
        }
      }
    }
  }
`;

export const CardWrapper = styled.section`
  max-width: 854px;
  width: 100%;
  height: auto;
`;

export const Modal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > h2 {
    font-size: 1.25rem;
    font-family: Izmir-Bold;
    color: #00a30b;
    width: 408px;
    margin-bottom: 14px;
  }

  > p {
    font-size: 1.37rem;
    font-family: Izmir-Medium;
    color: #0000ff;
    margin-bottom: 18.4px;
  }

  > a {
    color: #06e406;
    font-size: 1.37rem;
    font-family: Izmir-Medium;
    margin-top: 18.4px;
  }
`;
