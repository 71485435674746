import styled from 'styled-components';

export const Container = styled.div`
  max-width: 80.34vw;
  width: 100%;
  margin-left: 199.2px;
  margin-right: 69.2px;
  margin-top: 80px;

  > table {
    overflow-y: scroll;
    display: block;
  }

  .pagination {
    display: table;
    margin: 10px auto;
  }

  table {
    margin-top: 29.5px;
    width: 100%;
    border-collapse: collapse;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
    border-radius: 24px;

    th,
    td {
      text-align: left;
      padding: 24px 16px;
    }

    thead {
      tr {
        color: #0000ff;
        font-family: Izmir-Bold;
        font-size: 1.12rem;
      }
    }

    tbody {
      td {
        white-space: nowrap;
        font-family: Izmir-Regular;
        font-size: 14px;
        button {
          background-color: #06e406;
          color: #ffffff;
          font-family: Izmir-Bold;
          font-size: 13px;
          width: 88px;
          padding: 8.5px 0;
          border: none;
          border-radius: 24px;
          transition: background-color 1s;

          &:hover {
            background-color: #1b5e20;
          }
        }
      }

      tr:nth-child(odd) {
        background-color: #f0f0f0;
      }
    }
  }

  .skeleton {
    width: 90%;
    margin: 0px 0px 0 50px;
  }

  @media (max-width: 1392px) {
    margin-left: 180px;
  }

  @media (max-width: 1230px) {
    margin-left: 160px;
  }

  @media (max-width: 993px) {
    margin: 80px auto;
    max-width: 600px;
  }

  @media (max-width: 935px) {
    margin: 80px auto;
    max-width: 400px;
  }

  @media (max-width: 470px) {
    margin: 80px auto;
    max-width: 300px;
  }
`;
