import React from 'react';

import { Container } from './styles';

interface userProps {
  userInfo?: {
    email: string;
    individual_user: {
      name: string;
      cpf: string;
    };
  };
}

const Card: React.FC<userProps> = ({ children, userInfo }) => {
  return (
    <Container>
      {children}
      <h3>Informações de cadastro</h3>
      <div className="row">
        <p>{userInfo?.individual_user.name}</p>
        <p>{userInfo?.email}</p>
        <p>{userInfo?.individual_user.cpf}</p>
      </div>
      {/*
      <h3>Informações de pagamento</h3>
      <div className="row">
        <p>206 - Nu Pagamentos S.A</p>
        <p>001</p>
        <p>774495669-5</p>
      </div>
      */}
    </Container>
  );
};

export default Card;
