/* eslint-disable prefer-template */

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ModalWrap from '../../../components/ModalWrap';
import api from '../../../services/api';

import SuccessImg from '../../../assets/modalWrap/success.png';

import { Container, Modal } from './styles';

interface userProps {
  userInfo?: {
    id: string;
    value: string;
    agency: string;
    account: string;
    user_id: string;
    created_at: string;
    user: {
      email: string;
      individual_user?: {
        name: string;
        cpf: string;
      };
    };
    bank_code: {
      name: string;
    };
    status: {
      name: string;
      updated_at: string;
    };
  };
  setHideButton?: any;
}

const SolicitationsCard: React.FC<userProps> = ({
  userInfo,
  setHideButton,
}) => {
  const [isModal, setIsModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState('');
  const [selectedUserValue, setSelectedUserValue] = useState('');

  function getFormattedDate(date: any) {
    const newDate = new Date(date);
    const str =
      newDate.getFullYear() +
      '/' +
      (newDate.getMonth() + 1) +
      '/' +
      newDate.getDate();
    return str;
  }

  async function releaseWithdraw() {
    const { User } = JSON.parse(
      sessionStorage.getItem('ListzADM@UserAdmin') || '{}',
    );
    const headers = { Authorization: `Bearer ${User.token}` };
    try {
      await api.put(
        `/cashback-solicitations/${userInfo?.id}/confirm`,
        {},
        {
          headers,
        },
      );
      setHideButton(true);
      setIsModal(true);
    } catch (error) {
      console.log('');
    }
  }

  function renderModal() {
    if (isModal) {
      return (
        <ModalWrap toggleModal={() => setIsModal(false)}>
          <Modal>
            <h2>Cashback depositado!</h2>
            <p>
              Para {selectedUser}&nbsp;no valor de &nbsp;
              {moneyMask(selectedUserValue)}
            </p>
            <img src={SuccessImg} alt="sucesso" />
            <Link to={`/history/${selectedUser}`}>Ver histórico</Link>
          </Modal>
        </ModalWrap>
      );
    }
  }

  function moneyMask(value: any) {
    const money = parseFloat(value).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
    return money;
  }

  function handleWithDraw() {
    releaseWithdraw();
    setSelectedUser(`${userInfo?.user.individual_user?.name}`);
    setSelectedUserValue(`${userInfo?.value}`);
  }

  return (
    <>
      <Container>
        <h2>
          Saque {userInfo?.status?.name} - {moneyMask(userInfo?.value)} -
          {userInfo?.user.individual_user?.name}
        </h2>
        <h3>Informações de cadastro</h3>
        <div className="row">
          <p>{userInfo?.user.individual_user?.name}</p>
          <p>{userInfo?.user.email}</p>
          <p>{userInfo?.user.individual_user?.cpf}</p>
        </div>
        <h3>Informações de pagamento</h3>
        <div className="row">
          <p>{userInfo?.agency}</p>
          <p>{userInfo?.bank_code?.name}</p>
          <p>{userInfo?.account}</p>
        </div>

        <h2>{getFormattedDate(userInfo?.created_at)}</h2>
        {userInfo?.status?.name !== 'Depositado' && (
          <button type="button" onClick={() => handleWithDraw()}>
            Confirmar depósito
          </button>
        )}
      </Container>
      {renderModal()}
    </>
  );
};

export default SolicitationsCard;
