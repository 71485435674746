import React, { useEffect, useState, FormEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { FaTrash, FaSpinner } from 'react-icons/fa';
import LayoutWithoutImage from '../../components/LayoutWithoutImage';
import plusBtnImage from '../../assets/input/plusBtn.png';
import api from '../../services/api';
import Input from '../../components/Input';
import ModalWrap from '../../components/ModalWrap';
import SuccessImg from '../../assets/modalWrap/success.png';
import './styles.css';

import { Container, Menu, Form, TextArea, Modal, Button } from './styles';

interface productProps {
  external_id: string;
  real_url: string;
}

const ListCreate: React.FC = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [listName, setListName] = useState('');
  const [image, setImage] = useState<FileList | null>(null);
  const [description, setDescription] = useState('');
  const [categoriesLit, setCategoriesLit] = useState([]);
  const [categories, setCategories] = useState('');
  const [isModal, setIsModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [productsArray, setProductsArray] = useState<productProps[]>([]);
  const [products, setProducts] = useState<productProps | null>(null);
  const [productError, setProductError] = useState('');

  const [productLink, setProductLink] = useState<string>('');

  const [listImage, setListImage] = useState<string>();

  const history = useHistory();

  const { User } = JSON.parse(
    sessionStorage.getItem('ListzADM@UserAdmin') || '{}',
  );

  useEffect(() => {
    async function getCategories() {
      const res = await api.get('/categories');
      setCategoriesLit(res.data);
    }
    getCategories();
  }, []);

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;

    fileList && setImage(fileList);
    fileList && setListImage(URL.createObjectURL(fileList[0]));
  };

  async function handleSubmit(
    event: FormEvent<HTMLFormElement>,
  ): Promise<void> {
    event.preventDefault();

    if (
      !listName ||
      productsArray.length === 0 ||
      !description ||
      !categories
    ) {
      setErrorMessage('Insira todos os campos para cadastrar uma lista!');
    } else {
      setErrorMessage('');
      const codigo = productsArray.map((code: productProps) => {
        const externalRegex = code.external_id.match(
          /\/dp\/[A-Za-z0-9]*[\/-?]?/g,
        );
        const externalId =
          externalRegex && externalRegex[0].replace('?', '').split('/')[2];

        return { external_id: externalId };
      });

      const formData = new FormData();

      formData.append('name', listName);
      formData.append('description', description);
      formData.append('category_id', categories);
      formData.append('is_public', 'true');
      formData.append('products', JSON.stringify(codigo));

      if (image) {
        formData.append('image', image[0]);
      }

      const headers = { Authorization: `Bearer ${User.token}` };
      setLoading(true);
      try {
        await api.post(`lists`, formData, { headers });
        setIsModal(true);
        setProductsArray([]);
        setListName('');
        setDescription('');
        setLoading(false);
      } catch (error) {
        setErrorMessage(error.response?.data?.message || error.toString());
        setLoading(false);
      }
    }
  }

  function renderModal() {
    if (isModal) {
      return (
        <ModalWrap toggleModal={() => setIsModal(false)}>
          <Modal>
            <h2>Lista cadastrada com sucesso!</h2>
            <img src={SuccessImg} alt="sucesso" />
          </Modal>
        </ModalWrap>
      );
    }
  }

  const handleNewProductLink = (e: any) => {
    setProductLink(e.target.value);
    const urlAmazon = handleStringURL(e.target.value);
    setProducts(urlAmazon);
  };

  function handleStringURL(products: string) {
    let foundCode = false;

    let codeDP = '';
    for (let index = 0; index < products.length; index++) {
      if (foundCode) {
        if (products[index] === '/' || products[index] === '?') {
          break;
        }

        codeDP += products[index];
      }
      products;

      if (
        (products[index - 3] === '/' || products[index - 3] === '?') &&
        products[index - 1] === 'p' &&
        products[index - 2] === 'd' &&
        products[index - 3] === '/'
      ) {
        foundCode = true;
      } else if (
        (products[index] === '/' || products[index] === '?') &&
        products[index - 1] === 't' &&
        products[index - 2] === 'c' &&
        products[index - 3] === 'u' &&
        products[index - 4] === 'd' &&
        products[index - 5] === 'o' &&
        products[index - 6] === 'r' &&
        products[index - 7] === 'p' &&
        products[index - 8] === '/'
      ) {
        foundCode = true;
      }
    }

    return foundCode
      ? {
          external_id: `https://www.amazon.com.br/dp/${codeDP}`,
          real_url: products,
        }
      : { external_id: products, real_url: products };
  }

  function handleAddProduct() {
    if (products && products.external_id.includes('amazon.com')) {
      setProductsArray([
        ...productsArray,
        { external_id: products.external_id, real_url: products.real_url },
      ]);
      setProductError('');
      setProducts(null);
      setProductLink('');
    } else {
      setProductError('Insira um link da amazon válido!');
      setProducts(null);
    }
  }

  function renderLink(link: string) {
    const vetor = link.split('/');
    const vetorLink =
      vetor[0] + '/' + vetor[1] + '/' + vetor[2] + '/' + vetor[3];
    return vetorLink;
  }

  async function deleteProduct(index: number) {
    setProductsArray((productsArr) =>
      productsArr.filter((item, i) => i !== index),
    );
  }

  return (
    <LayoutWithoutImage>
      <Container>
        <Menu>
          <div>Criar lista</div>
          <p onClick={() => history.push('/minhas-listas')}>Listas criadas</p>
          <p onClick={() => history.push('/criar-categorias')}>
            Minhas categorias
          </p>
        </Menu>

        <h1>Criar e gerenciar listas</h1>
        <div>Crie e gerencie as listas que irão aparecer no app e web</div>

        <Form hasError={!!errorMessage} onSubmit={handleSubmit}>
          <div className="image-wrapper">
            <img className="image-wrapper__image" src={listImage} />
            <input
              id="avatar"
              accept="image/*"
              type="file"
              onChange={handleImageChange}
            />
          </div>

          <h1>Qual vai ser o nome da lista?</h1>
          <div>Insira o aqui o nome da lista.</div>
          <Input
            type="text"
            placeholder="Volta ás aulas"
            containerStyle={{
              marginTop: '11.5px',
              marginBottom: '23.4px',
              width: '222px',
            }}
            value={listName}
            onChange={(e) => {
              setListName(e.target.value);
            }}
          />
          <h1>Descrição da lista</h1>
          <div>Descreva brevemente essa lista.</div>
          <TextArea
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />
          <h1>Adicione os produtos</h1>
          <div>Adicione os produtos que deseja.</div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Input
              type="text"
              placeholder="Insira o link aqui..."
              containerStyle={{
                marginTop: '11.5px',
                marginBottom: '23.4px',
                width: '322px',
              }}
              value={productLink}
              onChange={(e: any) => handleNewProductLink(e)}
            />
            <img
              src={plusBtnImage}
              onClick={() => handleAddProduct()}
              style={{
                marginLeft: '10px',
                cursor: 'pointer',
              }}
              alt="plus btn"
            />
          </div>
          <p style={{ color: 'red', fontWeight: 'bold' }}>{productError}</p>
          {productsArray.map((product: productProps, index: number) => (
            <div style={{ display: 'flex' }}>
              <ul style={{ listStyle: 'none' }}>
                <li>
                  <a href={product.real_url}>{product.external_id}</a>
                </li>
              </ul>
              <FaTrash
                color="lightgrey"
                style={{
                  marginLeft: '10px',
                  cursor: 'pointer',
                }}
                onClick={() => deleteProduct(index)}
              />
            </div>
          ))}
          <h1>Adicione uma categoria</h1>
          <div>Selecione uma categoria</div>
          <select
            name="categories"
            id="categories"
            onChange={(e: any) => setCategories(e.target.value)}
            required
          >
            <option selected disabled>
              -
            </option>
            {categoriesLit.map((cat: any) => (
              <option value={cat.id}>{cat.name}</option>
            ))}
          </select>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              marginTop: '20px',
            }}
          >
            {errorMessage && <span>{errorMessage}</span>}
            {!loading ? (
              <Button type="submit">Cadastrar Lista</Button>
            ) : (
              <Button type="submit">
                <FaSpinner size={30} className="icon-spin" />
              </Button>
            )}
          </div>
        </Form>
      </Container>
      {renderModal()}
    </LayoutWithoutImage>
  );
};

export default ListCreate;
