import styled from 'styled-components';

export const Container = styled.div`
  .background-left {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 18.75vh;
  }

  .background-right {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 60vh;
  }

  .background-top {
    position: absolute;
    top: 13.41vh;
    right: 0;
    height: 21.48vh;
  }

  @media (max-width: 1267px) {
    .background-right {
      height: 50vh;
    }
  }

  @media (max-width: 1155px) {
    .background-right {
      display: none;
    }

    .background-top {
      display: none;
    }
  }

  @media (max-width: 700px) {
    .background-left {
      display: none;
    }
  }
`;
