import React, { useEffect, useState, FormEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { Switch } from 'antd';
import { toast } from 'react-toastify';
import { FaTrash, FaSpinner, FaPen, FaTrashAlt } from 'react-icons/fa';
import LayoutWithoutImage from '../../components/LayoutWithoutImage';
import plusBtnImage from '../../assets/input/plusBtn.png';
import api from '../../services/api';
import Input from '../../components/Input';
import ModalWrap from '../../components/ModalWrap';
import SuccessImg from '../../assets/modalWrap/success.png';
import './styles.css';

import {
  Container,
  Menu,
  Form,
  TextArea,
  Modal,
  Button,
  CategoriesContainer,
  CategoriesList,
} from './styles';

const CategoriesCreate: React.FC = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [errorMessageEdit, setErrorMessageEdit] = useState('');
  const [categoryName, setCategoryName] = useState('');
  const [description, setDescription] = useState('');
  const [isModalEdit, setIsModalEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [categoriesLit, setCategoriesLit] = useState<any>();
  const [deleteState, setDeleteState] = useState(false);
  const [categoryNameEdit, setCategoryNameEdit] = useState('');
  const [categoryDescriptionEdit, setCategoryDescriptionEdit] = useState('');
  const [categoryIdEdit, setCategoryIdEdit] = useState('');

  const history = useHistory();

  const { User } = JSON.parse(
    sessionStorage.getItem('ListzADM@UserAdmin') || '{}',
  );

  useEffect(() => {
    async function getCategories() {
      const res = await api.get('/categories');
      setCategoriesLit(res.data);
    }
    getCategories();
  }, [loading, deleteState, loadingEdit]);

  async function handleSubmit(
    event: FormEvent<HTMLFormElement>,
  ): Promise<void> {
    event.preventDefault();

    if (!categoryName || !description) {
      setErrorMessage('Insira todos os campos para cadastrar uma categoria!');
    } else {
      setErrorMessage('');

      const usr = {
        name: categoryName,
        description,
      };

      const headers = { Authorization: `Bearer ${User.token}` };
      setLoading(true);
      try {
        await api.post(`categories`, usr, { headers });
        setCategoryName('');
        setDescription('');
        setLoading(false);
        toast.success('Categoria cadastrada com sucesso!');
      } catch (error) {
        setErrorMessage(error.response?.data?.message || error.toString());
        setLoading(false);
      }
    }
  }

  async function handleSubmitEdit(
    event: FormEvent<HTMLFormElement>,
  ): Promise<void> {
    event.preventDefault();

    if (!categoryNameEdit || !categoryDescriptionEdit) {
      setErrorMessageEdit('Insira todos os campos para editar uma categoria!');
    } else {
      setErrorMessageEdit('');

      const usr = {
        name: categoryNameEdit,
        description: categoryDescriptionEdit,
      };

      const headers = { Authorization: `Bearer ${User.token}` };
      setLoadingEdit(true);
      try {
        await api.put(`categories/${categoryIdEdit}`, usr, { headers });
        setCategoryName('');
        setDescription('');
        setLoadingEdit(false);
        setIsModalEdit(false);
        toast.success('Lista editada com sucesso!');
      } catch (error) {
        toast.error(error.response?.data?.message || error.toString());
        setLoadingEdit(false);
      }
    }
  }

  function renderModalEdit() {
    if (isModalEdit) {
      return (
        <ModalWrap toggleModal={() => setIsModalEdit(false)}>
          <Modal>
            <Form hasError={!!errorMessageEdit} onSubmit={handleSubmitEdit}>
              <h4>Nome</h4>
              <Input
                type="text"
                containerStyle={{
                  marginTop: '11.5px',
                  marginBottom: '23.4px',
                  width: '222px',
                }}
                value={categoryNameEdit}
                onChange={(e) => {
                  setCategoryNameEdit(e.target.value);
                }}
              />
              <h4>Descrição</h4>
              <TextArea
                value={categoryDescriptionEdit}
                onChange={(e) => {
                  setCategoryDescriptionEdit(e.target.value);
                }}
              />
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  flexDirection: 'column',
                }}
              >
                {errorMessageEdit && <span>{errorMessageEdit}</span>}
                {!loadingEdit ? (
                  <Button type="submit">Editar categoria</Button>
                ) : (
                  <Button type="submit">
                    <FaSpinner size={30} className="icon-spin" />
                  </Button>
                )}
              </div>
            </Form>
          </Modal>
        </ModalWrap>
      );
    }
  }

  async function handleDelete(id: string | undefined) {
    try {
      const headers = { Authorization: `Bearer ${User.token}` };
      await api.delete(`categories/${id}`, {
        headers,
      });
      setDeleteState(!deleteState);
    } catch (error) {
      toast.error(error.response?.data?.message || error.toString());
    }
  }

  async function handleEdit(cat: any) {
    setIsModalEdit(true);
    setCategoryIdEdit(cat.id);
    setCategoryNameEdit(cat.name);
    setCategoryDescriptionEdit(cat.description);
  }

  const RenderList = (cat: any) => {
    const [highlight, setHighlight] = useState(cat.cat.is_highlight);

    useEffect(() => {
      setHighlight(cat.cat.is_highlight);
    }, [cat.cat.is_highlight]);

    async function handleSwitch(checked: boolean, id: string) {
      const headers = { Authorization: `Bearer ${User.token}` };
      try {
        await api.put(
          `/categories/${id}`,
          {
            is_highlight: checked,
          },
          {
            headers,
          },
        );
        if (checked) {
          toast.success(`Categoria visível`);
          setHighlight(true);
        } else {
          toast.success(`Categoria ocultada`);
          setHighlight(false);
        }
      } catch (error) {
        toast.error(error.response?.data?.message || error.toString());
      }
    }

    return (
      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        <p>{cat.cat.name}</p>
        <span style={{ margin: '0 -100px 0 -100px' }}>
          <FaPen
            style={{ cursor: 'pointer', margin: ' 0 25px 0 25px' }}
            onClick={() => handleEdit(cat.cat)}
          />
          <FaTrashAlt
            style={{ cursor: 'pointer' }}
            onClick={() => handleDelete(cat.cat.id)}
          />
        </span>
        <Switch
          checked={highlight}
          onClick={(value) => handleSwitch(value, cat.cat.id)}
        />
      </div>
    );
  };

  return (
    <LayoutWithoutImage>
      <Container>
        <Menu>
          <p onClick={() => history.push('/criar-listas')}>Criar lista</p>
          <p onClick={() => history.push('/minhas-listas')}>Listas criadas</p>
          <p
            className="active"
            onClick={() => history.push('/criar-categorias')}
          >
            Minhas categorias
          </p>
        </Menu>
        <CategoriesContainer>
          <Form hasError={!!errorMessage} onSubmit={handleSubmit}>
            <h1>Criar categoria</h1>
            <div>Insira o nome da categoria.</div>
            <Input
              type="text"
              containerStyle={{
                marginTop: '11.5px',
                marginBottom: '23.4px',
                width: '222px',
              }}
              value={categoryName}
              onChange={(e) => {
                setCategoryName(e.target.value);
              }}
            />
            <h1>Descrição da categoria</h1>
            <TextArea
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                flexDirection: 'column',
              }}
            >
              {errorMessage && <span>{errorMessage}</span>}
              {!loading ? (
                <Button type="submit">Cadastrar categoria</Button>
              ) : (
                <Button type="submit">
                  <FaSpinner size={30} className="icon-spin" />
                </Button>
              )}
            </div>
          </Form>
          <CategoriesList>
            <div>
              {categoriesLit &&
                categoriesLit.map((cat: any) => {
                  return <RenderList cat={cat} />;
                })}
            </div>
          </CategoriesList>
        </CategoriesContainer>
      </Container>
      {renderModalEdit()}
    </LayoutWithoutImage>
  );
};

export default CategoriesCreate;
