import React, { useState } from 'react';
import api from '../../../services/api';

import Button from '../../../components/Button';
import Input from '../../../components/Input';
import ProfileLayout from '../ProfileLayout';
import ModalWrap from '../../../components/ModalWrap';

import UserIcon from '../../../assets/input/user.png';
import SuccessImg from '../../../assets/modalWrap/success.png';

import { Container, Modal } from './styles';

const EditEmail: React.FC = () => {
  const [isModal, setIsModal] = useState(false);
  const [email, setEmail] = useState('');

  const { UserInfo, User } = JSON.parse(
    sessionStorage.getItem('ListzADM@UserAdmin') || '{}',
  );

  const handleChangeEmail = async () => {
    try {
      const headers = { Authorization: `Bearer ${User.token}` };
      await api.put(`users/${UserInfo.userInfo.id}`, { email }, { headers });
      setIsModal(true);
    } catch (error) {
      console.error('Ops! ocorreu um erro', error.response.data.message);
    }
  };

  function renderModal() {
    if (isModal) {
      return (
        <ModalWrap toggleModal={() => setIsModal(false)}>
          <Modal>
            <h2>E-mail alterado com sucesso!</h2>
            <img src={SuccessImg} alt="sucesso" />
          </Modal>
        </ModalWrap>
      );
    }
  }
  return (
    <>
      <ProfileLayout>
        <Container>
          <h2>Alterar E-mail</h2>
          <Input
            icon={UserIcon}
            type="email"
            placeholder="Insira o novo e-mail"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            containerStyle={{ marginTop: '26.5px' }}
          />
          <Button
            containerStyle={{ marginTop: '29.3px', marginBottom: '37px' }}
            text="Alterar e-mail"
            handleClick={() => handleChangeEmail()}
          />
        </Container>
      </ProfileLayout>
      {renderModal()}
    </>
  );
};

export default EditEmail;
