import styled from 'styled-components';

export const Container = styled.div`
  max-width: 854px;
  background-color: #f1f1f1;
  border-radius: 24px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  > h2 {
    font-family: Izmir-Bold;
    font-size: 1.37rem;
    color: #727272;
    margin-bottom: 12px;
  }

  > h3 {
    font-family: Izmir-Medium;
    font-size: 1rem;
    color: #838383;
    margin-bottom: 10px;
  }

  .row {
    display: flex;
    max-width: 600px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    margin-left: 0px;

    > p {
      font-family: Izmir-Regular;
      font-size: 1rem;
      color: #838383;
    }
  }

  > button {
    width: 258px;
    height: 57px;
    background-color: #06e406;
    color: #ffffff;
    font-size: 1.62rem;
    font-family: Izmir-Bold;
    border: none;
    border-radius: 24px;
    align-self: flex-end;

    transition: background-color 1s;

    &:hover {
      background-color: #1b5e20;
    }
  }

  h2:last-child {
    align-self: flex-end;
  }

  @media (max-width: 780px) {
    height: auto;
    .row {
      flex-direction: column;
      align-items: flex-start;
      justify-content: initial;
    }
  }
`;

export const Modal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > h2 {
    font-size: 2.25rem;
    font-family: Izmir-Bold;
    color: #00a30b;
    margin-bottom: 45px;
  }

  > p {
    font-size: 1.37rem;
    font-family: Izmir-Medium;
    color: #0000ff;
    margin-bottom: 18.4px;
  }

  > a {
    color: #06e406;
    font-size: 1.37rem;
    font-family: Izmir-Medium;
    margin-top: 18.4px;
  }
`;
