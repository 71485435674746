import styled from 'styled-components';

export const Container = styled.div`
  .background-top {
    position: absolute;
    top: 13.41vh;
    right: 0;
    height: 21.48vh;
  }

  @media (max-width: 1155px) {
    .background-top {
      display: none;
    }
  }
`;
