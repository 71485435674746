import styled from 'styled-components';

export const Container = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 53px;
  background-color: #2f78fe;
  border-radius: 10px;
  border-width: 0px;
  outline: none;
  padding: 20px;
`;

export const Text = styled.span`
  font-size: 1.25rem;
  border-style: none;
  color: #fff;
  font-family: 'Izmir-Medium';
`;
