import styled from 'styled-components';

export const ModalError = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > h2 {
    font-size: 1.25rem;
    font-family: Izmir-Bold;
    color: #00a30b;
    width: 500px;
    margin-bottom: 14px;
  }

  > p {
    font-size: 1.37rem;
    font-family: Izmir-Medium;
    color: #0000ff;
    margin-bottom: 18.4px;
  }

  > a {
    color: #06e406;
    font-size: 1.37rem;
    font-family: Izmir-Medium;
    margin-top: 18.4px;
  }
`;

export const Modal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > h2 {
    font-size: 2.25rem;
    font-family: Izmir-Bold;
    color: #00a30b;
    width: 300px;
    margin-bottom: 14px;
  }

  > p {
    font-size: 1.37rem;
    font-family: Izmir-Medium;
    color: #0000ff;
    margin-bottom: 18.4px;
  }

  > a {
    color: #06e406;
    font-size: 1.37rem;
    font-family: Izmir-Medium;
    margin-top: 18.4px;
  }
`;
