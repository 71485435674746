import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    font-family: Izmir-Bold;
    font-size: 3.56rem;
    color: #2f78fe;
  }

  .email-card {
    display: flex;
    align-items: center;

    p {
      font-family: Izmir-Regular;
      font-size: 1.56rem;
      margin-right: 8px;
      strong {
        font-family: Izmir-Bold;
        color: #2f78fe;
        font-size: 1.56rem;
      }
    }
    > a {
      text-decoration: none;
    }
  }

  a {
    font-size: 1.12rem;
    font-family: Izmir-Medium;
    color: #2f78fe;
    margin-top: 36.5px;
    margin-bottom: 47.5px;
  }

  @media (max-width: 450px) {
    .email-card {
      p {
        margin-right: 12px;
      }
    }
  }
`;
