import React, { useEffect, useState, useMemo } from 'react';
import { Pagination, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import socketio from 'socket.io-client';
import LayoutWithoutImage from '../../components/LayoutWithoutImage';
import NotificationCard from './NotifcationCard';
import api from '../../services/api';

import { Container } from './styles';

const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

interface notificationsInterface {
  id: string;
  content: string;
  read: Boolean;
  created_at: string;
}

const Notification: React.FC = () => {
  const [notifications, setNotifications] = useState<notificationsInterface[]>(
    [],
  );
  const [loading, setLoading] = useState(false);
  const [hideButton, setHideButton] = useState(false);

  const { User, UserInfo } = JSON.parse(
    sessionStorage.getItem('ListzADM@UserAdmin') || '{}',
  );

  const socket = useMemo(
    () =>
      socketio('https://api.listz.com.br', {
        query: {
          user_id: UserInfo.userInfo.id,
        },
      }),
    [UserInfo.userInfo.id],
  );

  useEffect(() => {
    socket.on('notification', (notification: any) => {
      setNotifications([notification, ...notifications]);
    });
  }, [socket, notifications]);

  useEffect(() => {
    async function notificationsIndex() {
      try {
        const headers = { Authorization: `Bearer ${User.token}` };
        const response = await api.get('/notifications', { headers });
        setNotifications(response.data.results);
        setLoading(true);
      } catch (error) {
        console.log('');
      }
    }

    notificationsIndex();
  }, [notifications, hideButton]);

  return (
    <LayoutWithoutImage>
      <Container>
        <h1>Notificações</h1>
        <div className="notifications">
          {loading ? (
            <>
              {notifications.map((results) => (
                <NotificationCard
                  notificationsInfo={results}
                  setHideButton={setHideButton}
                />
              ))}
            </>
          ) : (
            <div className="loading">
              <Spin indicator={antIcon} />
            </div>
          )}
        </div>
      </Container>
    </LayoutWithoutImage>
  );
};

export default Notification;
