import React from 'react';

import { Container } from './styles';

interface userProps {
  userInfo?: {
    id: string;
    value: string;
    agency: string;
    account: string;
    email: string;
    individual_user?: {
      name: string;
      cpf: string;
    };
    bank: {
      name: string;
      agency: string;
      account: string;
      bank_code: {
        name: string;
      };
    };
    status: {
      name: string;
      updated_at: string;
    };
  };
}

const CardSelected: React.FC<userProps> = ({ children, userInfo }) => {
  return (
    <Container>
      {children}
      <h3>Informações de cadastro</h3>
      <div className="row">
        <p>{userInfo?.individual_user?.name}</p>
        <p>{userInfo?.email}</p>
        <p>{userInfo?.individual_user?.cpf}</p>
      </div>
      <h3>Informações de pagamento</h3>
      <div className="row">
        <p>{userInfo?.bank?.bank_code.name}</p>
        <p>{userInfo?.bank?.agency}</p>
        <p>{userInfo?.bank?.account}</p>
      </div>
    </Container>
  );
};

export default CardSelected;
