import { createGlobalStyle } from 'styled-components';

import 'react-toastify/dist/ReactToastify.css';

import IzmirBlack from '../assets/fonts/Izmir-Black.otf';
import IzmirBold from '../assets/fonts/Izmir-Bold.otf';
import IzmirRegular from '../assets/fonts/Izmir-Regular.otf';
import IzmirMedium from '../assets/fonts/Izmir-Medium.otf';
import PoppinsMedium from '../assets/fonts/Poppins-Medium.ttf';
import PoppinsRegular from '../assets/fonts/Poppins-Regular.ttf';
import IzmirLight from '../assets/fonts/Izmir-Light.otf';

export default createGlobalStyle`

  @font-face {
    font-family: Izmir-Black;
    src: local('Izmir-Black'), url(${IzmirBlack});
  }

  @font-face {
    font-family: Izmir-Bold;
    src: local('Izmir-Bold'), url(${IzmirBold});
  }

  @font-face {
    font-family: Izmir-Regular;
    src: local('Izmir-Regular'), url(${IzmirRegular});
  }

  @font-face {
    font-family: Izmir-Medium;
    src: local('Izmir-Medium'), url(${IzmirMedium});
  }

  @font-face {
    font-family: Poppins-Medium;
    src: local('Poppins-Medium'), url(${PoppinsMedium});
  }

  @font-face {
    font-family: Poppins-Regular;
    src: local('Poppins-Regular'), url(${PoppinsRegular});
  }

  @font-face {
    font-family: Izmir-Light;
    src: local('Izmir-Light'), url(${IzmirLight});
  }

  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
    font-size: 16px;
  }

  @media(max-width: 768px) {
    *{
      font-size: 14px;
    }
  }

  body {
    color: #212121;
    -webkit-font-smoothing: antialiased;
  }

  body, input, button {
    font-family: 'Ubuntu', sans-serif;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 500;
  }

  button {
    cursor: pointer;
  }
`;
