import styled from 'styled-components';

interface FormProps {
  hasError: boolean;
}

export const Container = styled.div`
  margin-left: 184px;
  margin-right: 11.42vw;
  margin-top: 68px;
  margin-bottom: 24px;

  h1 {
    font-size: 2.25rem;
    color: #2f78fe;
    font-family: Izmir-Bold;
    margin-bottom: 26px;
  }

  .image-wrapper {
    display: flex;
    flex-direction: column;
    gap: 15px;

    padding: 15px 0;
  }

  .image-wrapper__image {
    width: 100%;
    max-width: 150px;
    height: auto;
  }

  .loading {
    display: table;
    margin: 0 auto;
  }

  select {
    border: none;
    background-color: #f5f5f5;
    font-size: 1.18rem;
    font-family: Izmir-Regular;
    color: #979797;
    height: 54.5px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    padding: 16px;
    margin: 16px 0 16px 0;
    width: 200px;
  }

  @media (max-width: 700px) {
    margin: 68px auto;
    max-width: 500px;
  }

  @media (max-width: 540px) {
    max-width: 90vw;
  }
`;

export const Modal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > h2 {
    font-size: 2.25rem;
    font-family: Izmir-Bold;
    color: #00a30b;
    width: 300px;
    margin-bottom: 50.5px;
  }
`;

export const Menu = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 50px;

  > div {
    background: #d5e0fc;
    color: #2f78fe;
    padding: 14px 14px 14px 14px;
    border-radius: 6px;
    width: 150px;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
  }

  > p {
    color: #2f78fe;
    align-self: center;
    margin: 0;
    font-weight: bold;
    margin-left: 50px;
    cursor: pointer;
  }

  > p:hover {
    background: #d5e0fc;
    color: #2f78fe;
    padding: 14px 14px 14px 14px;
    border-radius: 6px;
    width: 190px;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
  }
`;

export const Form = styled.form<FormProps>`
  > div {
    > span {
      color: red;
      font-weight: 600;
    }
  }

  & > input {
    margin-bottom: 20px;
  }
`;

export const TextArea = styled.textarea`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  width: 100%;
  height: 64px;
  background-color: #f6f6f6;
  border-radius: 10px;
  margin: 20px 0px;
  padding: 0px 20px;
  padding-right: 48px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
  border: 0;
  width: 322px;
  height: 92px;
`;

export const CategoryTag = styled.div`
  background: #d5e0fc;
  color: #2f78fe;
  padding: 8px 8px 8px 8px;
  border-radius: 20px;
  width: 100px;
  text-align: center;
  font-weight: bold;
`;

export const Button = styled.button`
  background: #2f78fe;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  color: #fff;
  border: 0;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
`;
