import styled from 'styled-components';

export const Container = styled.div`
  max-width: 548px;
  width: 100%;
  margin-bottom: 24px;
  background-color: #e3ffeb;
  border-radius: 24px;
  padding: 16px;
  display: flex;
  flex-direction: column;

  > div {
    display: flex;
    justify-content: space-between;
  }

  h2 {
    font-family: Izmir-Bold;
    font-size: 1.37rem;
    color: #00a30b;
    margin-bottom: 12.2px;
  }

  h3 {
    font-family: Izmir-Medium;
    font-size: 1rem;
    color: #00a30b;
    margin-bottom: 12.2px;
    margin-bottom: 8px;
  }

  button {
    width: 210px;
    background-color: #06e406;
    border: none;
    border-radius: 24px;
    height: 33px;
    font-size: 1rem;
    font-family: Izmir-Bold;
    color: #ffffff;
    transition: background-color 1s;
    align-self: flex-end;
    margin-top: 20.6px;

    &:hover {
      background-color: #1b5e20;
    }
  }

  .design-direction {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;

    p {
      color: #838383;
      font-family: Izmir-Regular;
      font-size: 1rem;
      & + p {
        margin-left: 12px;
      }
    }
  }

  @media (max-width: 706px) {
    height: auto;

    .design-direction {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  @media (max-width: 400px) {
    > button {
      align-self: center;
    }
  }
`;
