import React, { useState } from 'react';
import { ImMenu } from 'react-icons/im';
import { IoMdCloseCircle } from 'react-icons/io';
import { Link } from 'react-router-dom';

import homeImg from '../../assets/verticalTab/home.png';
import moneyImg from '../../assets/verticalTab/cash.png';
import notificationImg from '../../assets/verticalTab/notification.png';

import LogoImg from '../../assets/header/logo.png';

import { Container } from './styles';

const Header: React.FC = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const [newNotification, setNewNotification] = useState(true); // trocar para false qdo for fazer a lógica

  function renderNotification() {
    if (newNotification) {
      return <span />;
    }
  }
  return (
    <Container menu={openMenu}>
      <button
        type="button"
        className="close-button"
        onClick={() => setOpenMenu(false)}
      >
        <IoMdCloseCircle />
      </button>
      <img src={LogoImg} alt="logo" />

      <button
        className="menu-button"
        type="button"
        onClick={() => setOpenMenu(true)}
      >
        <ImMenu />
      </button>

      <nav>
        <Link to="/profile">
          <img
            className="avatar"
            src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixid=MXwxMjA3fDB8MHxzZWFyY2h8MXx8cG9ydHJhaXR8ZW58MHx8MHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=400&q=60"
            alt="avatar"
          />
        </Link>

        <Link to="/home">
          <img
            style={{ width: '49px', height: 'auto' }}
            src={homeImg}
            alt="home-icon"
          />
        </Link>

        <Link to="/cashback">
          <img
            style={{ width: '40.4px', height: 'auto' }}
            src={moneyImg}
            alt="money-icon"
          />
        </Link>

        <Link to="/notification">
          <img
            style={{ width: '30.57px', height: 'auto' }}
            src={notificationImg}
            alt="notification-icon"
          />
          {renderNotification()}
        </Link>
      </nav>
    </Container>
  );
};

export default Header;
