import React, { useEffect, useState, FormEvent } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { FaTrash, FaSpinner } from 'react-icons/fa';
import LayoutWithoutImage from '../../components/LayoutWithoutImage';
import plusBtnImage from '../../assets/input/plusBtn.png';
import api from '../../services/api';
import Input from '../../components/Input';
import ModalWrap from '../../components/ModalWrap';
import SuccessImg from '../../assets/modalWrap/success.png';

import {
  Container,
  Menu,
  Form,
  TextArea,
  Modal,
  Button,
  ButtonsWrapper,
} from './styles';
import { toast } from 'react-toastify';

const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

interface productProps {
  id?: string;
  external_id: string;
  real_url: string;
  item_url?: string;
}

interface locationProps {
  listId: string;
  listCategory: number;
}

const ListCreate: React.FC = () => {
  const history = useHistory();
  const location = useLocation<locationProps>();

  const [errorMessage, setErrorMessage] = useState('');
  const [listName, setListName] = useState('');
  const [description, setDescription] = useState('');
  const [categoriesLit, setCategoriesLit] = useState([]);
  const [categories, setCategories] = useState(0);
  const [categoriesName, setCategoriesName] = useState('');
  const [isModal, setIsModal] = useState(false);
  const [productsArray, setProductsArray] = useState<productProps[]>([]);
  const [productsArrayShow, setProductsArrayShow] = useState<productProps[]>(
    [],
  );
  const [products, setProducts] = useState<productProps | null>(null);
  const [productError, setProductError] = useState('');
  const [deleteState, setDeleteState] = useState(false);
  const [loading, setLoading] = useState(false);

  const [confirmDelete, setConfirmDelete] = useState(false);

  const [productLink, setProductLink] = useState<string>('');

  const [originalmage, setOriginalmage] = useState<string>('');
  const [listImage, setListImage] = useState<string>();

  const { User } = JSON.parse(
    sessionStorage.getItem('ListzADM@UserAdmin') || '{}',
  );

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;

    fileList && setListImage(URL.createObjectURL(fileList[0]));

    if (fileList) {
      const formData = new FormData();
      formData.append('image', fileList[0]);

      try {
        await api.put(`lists/${location.state.listId}/image`, formData, {
          headers: {
            'Content-Type': `multipart/form-data;`,
            Authorization: `Bearer ${User.token}`,
          },
        });

        setIsModal(true);
      } catch (error) {
        setErrorMessage(error.response?.data?.message || error.toString());
      }
    }
  };

  async function getList() {
    const res = await api.get(`/lists/${location.state.listId}`);
    setListName(res.data.name);
    setDescription(res.data.description);
    setCategories(location.state.listCategory);
    setCategoriesName(res.data.category.name);
    setProductsArrayShow(res.data.products);
    setOriginalmage(res.data.image_url);
  }


  useEffect(() => {
    getList();
  }, [deleteState]);

  useEffect(() => {
    async function getCategories() {
      const res = await api.get('/categories');
      setCategoriesLit(res.data);
    }
    getCategories();
  }, []);

  if (location.state === undefined) {
    history.push('/minhas-listas');
  }

  async function handleSubmit(
    event: FormEvent<HTMLFormElement>,
  ): Promise<void> {
    event.preventDefault();

    if (!listName || !description || !categories) {
      setErrorMessage('Insira todos os campos para para continuar!');
    } else {
      setErrorMessage('');

      const codigo = productsArray.map((code: productProps) => {
        const externalRegex = code.external_id.match(
          /\/dp\/[A-Za-z0-9]*[\/-?]?/g,
        );
        const externalId =
          externalRegex && externalRegex[0].replace('?', '').split('/')[2];

        return { external_id: externalId };
      });

      const data = {
        name: listName,
        description,
        category_id: String(categories),
        is_public: true,
      };

      const usrProd = {
        products: codigo,
      };

      const headers = { Authorization: `Bearer ${User.token}` };
      setLoading(true);

      try {
        await api.put(`lists/${location.state.listId}`, data, { headers });

        if (codigo.length > 0) {
          await api.post(`lists/${location.state.listId}/products`, usrProd, {
            headers,
          });

          setProductsArray([]);
        }

        setIsModal(true);
        setLoading(false);
      } catch (error) {
        setErrorMessage(error.response?.data?.message || error.toString());
        setLoading(false);
      }
    }
  }

  function renderModal() {
    if (isModal) {
      return (
        <ModalWrap toggleModal={() => setIsModal(false)}>
          <Modal>
            <h2>Lista editada com sucesso!</h2>
            <img src={SuccessImg} alt="sucesso" />
          </Modal>
        </ModalWrap>
      );
    }
  }

  const handleNewProductLink = (e: any) => {
    setProductLink(e.target.value);
    const urlAmazon = handleStringURL(e.target.value);
    setProducts(urlAmazon);
  };

  function handleAddProduct() {
    if (products && products.external_id.includes('amazon.com')) {
      setProductsArray([
        ...productsArray,
        { external_id: products.external_id, real_url: products.real_url },
      ]);
      setProductError('');
      setProducts(null);
      setProductLink('');
    } else {
      setProductError('Insira um link da amazon válido!');
    }
  }

  function renderLink(link: string) {
    const vetor = link.split('/');
    const vetorLink =
      vetor[0] +
      '/' +
      vetor[1] +
      '/' +
      vetor[2] +
      '/' +
      vetor[3] +
      '/' +
      vetor[4];
    return vetorLink;
  }

  async function deleteProduct(id: string | undefined) {
    try {
      const headers = { Authorization: `Bearer ${User.token}` };
      await api.delete(`lists/${location.state.listId}/products/${id}`, {
        headers,
      });
      setDeleteState(!deleteState);
    } catch (error) {
      setErrorMessage(error.response?.data?.message || error.toString());
    }
  }

  async function deleteProductLink(index: number) {
    setProductsArray((productsArr) =>
      productsArr.filter((item, i) => i !== index),
    );
  }

  function handleStringURL(products: string) {
    let foundCode = false;

    let codeDP = '';
    for (let index = 0; index < products.length; index++) {
      if (foundCode) {
        if (products[index] === '/' || products[index] === '?') {
          break;
        }

        codeDP += products[index];
      }
      products;

      if (
        (products[index - 3] === '/' || products[index - 3] === '?') &&
        products[index - 1] === 'p' &&
        products[index - 2] === 'd' &&
        products[index - 3] === '/'
      ) {
        foundCode = true;
      } else if (
        (products[index] === '/' || products[index] === '?') &&
        products[index - 1] === 't' &&
        products[index - 2] === 'c' &&
        products[index - 3] === 'u' &&
        products[index - 4] === 'd' &&
        products[index - 5] === 'o' &&
        products[index - 6] === 'r' &&
        products[index - 7] === 'p' &&
        products[index - 8] === '/'
      ) {
        foundCode = true;
      }
    }

    return foundCode
      ? {
          external_id: `https://www.amazon.com.br/dp/${codeDP}`,
          real_url: products,
        }
      : { external_id: products, real_url: products };
  }

  const handleDelete = (event: any) => {
    event.preventDefault();

    confirmDelete ? deleteList() : setConfirmDelete(true);
  };

  const deleteList = async () => {
    const headers = { Authorization: `Bearer ${User.token}` };

    setLoading(true);
    try {
      const response = await api.delete(`lists/${location.state.listId}`, {
        headers,
      });

      if (response) {
        toast.success('Lista deletada com sucesso');

        setTimeout(() => {
          history.goBack();
        }, 4000);
      }
    } catch (error) {
      handleError(error.response?.data?.message || error.toString());
    } finally {
      setLoading(false);
      setConfirmDelete(false);
    }
  };

  const handleError = (error: string) => {
    toast.error(error);
  };

  const warningClass = () => {
    return confirmDelete ? 'delete-button__warning' : 'delete-button';
  };

  return (
    <LayoutWithoutImage>
      <Container>
        <Menu>
          <div onClick={() => history.push('/criar-listas')}>Criar lista</div>
          <p onClick={() => history.push('/minhas-listas')}>Listas criadas</p>
          <p
            className="active"
            onClick={() => history.push('/criar-categorias')}
          >
            Minhas categorias
          </p>
        </Menu>

        <h1>Editando lista</h1>
        <div>Edite essa lista aqui.</div>

        <Form hasError={!!errorMessage} onSubmit={handleSubmit}>
          <h1>Imagem da lista (opcional)</h1>
          <div className="image-wrapper">
            <img
              className="image-wrapper__image"
              src={listImage || originalmage}
            />
            <input
              id="avatar"
              accept="image/*"
              type="file"
              onChange={handleImageChange}
            />
          </div>
          <h1>Editar nome da lista</h1>

          <div>Insira o aqui o nome da lista.</div>
          <Input
            type="text"
            containerStyle={{
              marginTop: '11.5px',
              marginBottom: '23.4px',
              width: '222px',
            }}
            value={listName}
            onChange={(e) => {
              setListName(e.target.value);
            }}
          />
          <h1>Descrição da lista</h1>
          <div>Descreva brevemente essa lista.</div>
          <TextArea
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />
          <h1>Adicione os produtos</h1>
          <div>Adicione os produtos que deseja.</div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Input
              type="text"
              placeholder="Insira o link aqui..."
              containerStyle={{
                marginTop: '11.5px',
                marginBottom: '23.4px',
                width: '322px',
              }}
              value={productLink}
              onChange={(e: any) => handleNewProductLink(e)}
            />
            <img
              src={plusBtnImage}
              onClick={() => handleAddProduct()}
              style={{
                marginLeft: '10px',
                cursor: 'pointer',
              }}
              alt="plus btn"
            />
          </div>
          {productsArrayShow.map((prodArr) => (
            <div style={{ display: 'flex' }}>
              <ul style={{ listStyle: 'none' }}>
                <li>
                  <a
                    href={`${prodArr.item_url}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {prodArr.item_url}
                  </a>
                </li>
              </ul>
              <FaTrash
                color="lightgrey"
                style={{
                  marginLeft: '10px',
                  cursor: 'pointer',
                }}
                onClick={() => deleteProduct(prodArr.id)}
              />
            </div>
          ))}
          <p style={{ color: 'red', fontWeight: 'bold' }}>{productError}</p>
          {productsArray.map((product: productProps, index: number) => (
            <div style={{ display: 'flex' }}>
              <ul style={{ listStyle: 'none' }}>
                <li>
                  <a href={product.real_url}>{product.external_id}</a>
                </li>
              </ul>
              <FaTrash
                color="lightgrey"
                style={{
                  marginLeft: '10px',
                  cursor: 'pointer',
                }}
                onClick={() => deleteProductLink(index)}
              />
            </div>
          ))}
          <h1>Adicione uma categoria</h1>
          <div>Selecione uma categoria</div>
          <select
            name="categories"
            id="categories"
            onChange={(e: any) => setCategories(e.target.value)}
            required
          >
            <option selected disabled>
              {categoriesName}
            </option>
            {categoriesLit.map((cat: any) => (
              <option value={cat.id}>{cat.name}</option>
            ))}
          </select>

          <ButtonsWrapper>
            {!loading ? (
              <Button type="submit">Editar Lista</Button>
            ) : (
              <Button disabled>
                <FaSpinner size={30} className="icon-spin" />
              </Button>
            )}

            {!loading ? (
              <Button
                className={warningClass()}
                onClick={(e) => handleDelete(e)}
              >
                {!confirmDelete ? 'Excluir lista' : 'Tem certeza?'}
              </Button>
            ) : (
              <Button disabled>
                <FaSpinner size={30} className="icon-spin" />
              </Button>
            )}
            {errorMessage && <span>{errorMessage}</span>}
          </ButtonsWrapper>
        </Form>
      </Container>
      {renderModal()}
    </LayoutWithoutImage>
  );
};

export default ListCreate;
