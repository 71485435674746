import styled from 'styled-components';

export const Container = styled.div`
  padding: 52px 53.73vw 135px 226px;

  section {
    width: 406px;
    width: 563px;
    height: auto;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 24px;

    .image-card {
      position: relative;
      width: 173px;
      height: 200px;
      .avatar {
        width: 173px;
        height: 173px;
        border: 3px solid #0000ff;
        border-radius: 100%;
        object-fit: cover;
        object-position: top;
      }
      .camera {
        position: absolute;
        bottom: 0;
        right: 0;
        background-color: #06e406;
        border-radius: 100%;
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  label {
    cursor: pointer;
    margin: 0;
    &:hover {
      opacity: 0.7;
    }
    input {
      display: none;
    }
  }

  .skeleton {
    width: 72%;
  }

  @media (max-width: 739px) {
    padding: 52px 30.32vw 135px 30.58vw;
  }

  @media (max-width: 700px) {
    padding: 52px 0 135px;

    section {
      margin: 0 auto;
    }
  }

  @media (max-width: 450px) {
    section {
      max-width: 300px;
      padding-left: 8px;
      padding-right: 8px;
    }
  }
`;
