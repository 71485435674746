import styled from 'styled-components';

interface UserSelectProps {
  disabled: boolean;
}

export const Container = styled.div<UserSelectProps>`
  > div {
    margin-left: 184px;
    margin-right: 7.97vw;
    margin-top: 36px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 24px;

    .users-search {
      max-width: 529px;
      width: 100%;

      h1 {
        font-size: 2.25rem;
        color: #2f78fe;
        font-family: Izmir-Bold;
        margin-bottom: 8px;
      }

      h2 {
        font-size: 1.87rem;
        color: #2f78fe;
        font-family: Izmir-Bold;
        margin-top: 23.8px;
        margin-bottom: 8px;
      }

      h4 {
        font-size: 1.25rem;
        font-family: Izmir-Regular;
        margin-bottom: 30.7px;
        margin-top: 7.8px;
      }

      .cashback {
        opacity: ${props => (props.disabled ? 0.5 : 1)};
      }

      .input-direction {
        display: flex;
        opacity: ${props => (props.disabled ? 0.8 : 1)};
        align-items: center;
        justify-content: space-between;
      }
    }

    .right-card {
      margin-top: 110px;
      margin-left: 16px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-between;
      height: 355px;

      section {
        border-radius: 24px;
        background-color: #ffffff;
        border: 3px solid #06e406;

        h5 {
          font-size: 1.37rem;
          font-family: Izmir-Bold;
          color: #00a30b;
          margin-bottom: 8.8px;
        }
      }

      > button {
        width: 258px;
        height: 57px;
        background-color: #06e406;
        color: #ffffff;
        font-size: 1.62rem;
        font-family: Izmir-Bold;
        border: none;
        border-radius: 24px;

        transition: background-color 1s;

        &:hover {
          background-color: #1b5e20;
        }
      }
    }
  }

  .withdrawInput {
    font-size: 1.4rem;
    font-family: 'Izmir-Regular';
    color: #c5c5c5;
    width: 100%;
    height: 50px;
    background-color: #f6f6f6;
    border-radius: 10px;
    margin: 0px 0px;
    padding: 0px 20px;
    padding-right: 48px;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 20%), 0 6px 10px 0 rgb(0 0 0 / 19%);
    border: 0;
    outline: none;
    width: 200px;
  }

  .pagination {
    display: table;
    margin: 10px auto;
  }

  table {
    margin-top: 29.5px;
    width: 100%;
    border-collapse: collapse;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
    border-radius: 24px;

    th,
    td {
      text-align: center;
      padding: 24px 16px;
    }

    thead {
      tr {
        color: #0000ff;
        font-family: Izmir-Bold;
        font-size: 1.12rem;
      }
    }

    tbody {
      td {
        white-space: nowrap;
        font-family: Izmir-Regular;
        font-size: 14px;
        text-align: center;
        button {
          background-color: #06e406;
          color: #ffffff;
          font-family: Izmir-Bold;
          font-size: 13px;
          width: 60%;
          padding: 8.5px 0;
          border: none;
          border-radius: 24px;
          transition: background-color 1s;

          &:hover {
            background-color: #1b5e20;
          }
        }
      }

      tr:nth-child(odd) {
        background-color: #f0f0f0;
      }
    }
  }

  .skeleton {
    width: 90%;
    margin: 0px 0px 0 50px;
  }

  @media (max-width: 1299px) {
    > div {
      margin-right: 8.4vw;
    }
  }

  @media (max-width: 1263px) {
    > div {
      margin-right: 8vw;
    }
  }

  @media (max-width: 1155px) {
    > div {
      margin: 68px auto 24px;
      flex-direction: column-reverse;
      align-items: center;

      .right-card {
        margin-top: 0;
        margin-left: 0;
        margin-bottom: 16px;
        height: auto;
        max-width: 529px;
        width: 100%;
      }
      section {
        width: 100%;
        margin-bottom: 16px;
      }
    }
  }

  @media (max-width: 860px) {
    > div {
      .users-search {
        .input-direction {
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
        }
      }
      .users-search,
      .right-card {
        width: 400px;
      }
    }
  }

  @media (max-width: 442px) {
    > div {
      .users-search,
      .right-card {
        width: 300px;
      }
    }
  }
`;

export const TableContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Modal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > h2 {
    font-size: 2.25rem;
    font-family: Izmir-Bold;
    color: #00a30b;
    width: 300px;
    margin-bottom: 14px;
  }

  > p {
    font-size: 1.37rem;
    font-family: Izmir-Medium;
    color: #0000ff;
    margin-bottom: 18.4px;
  }

  > a {
    color: #06e406;
    font-size: 1.37rem;
    font-family: Izmir-Medium;
    margin-top: 18.4px;
  }
`;

export const ModalError = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > h2 {
    font-size: 1.25rem;
    font-family: Izmir-Bold;
    color: #00a30b;
    width: 500px;
    margin-bottom: 14px;
  }

  > p {
    font-size: 1.37rem;
    font-family: Izmir-Medium;
    color: #0000ff;
    margin-bottom: 18.4px;
  }

  > a {
    color: #06e406;
    font-size: 1.37rem;
    font-family: Izmir-Medium;
    margin-top: 18.4px;
  }
`;

export const PendingCashback = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 25px;
  width: 300px;
  padding: 10px 20px 10px 20px;

  h5 {
    color: #2f78fe;
  }

  h5,
  h4 {
    margin-top: 10px;
  }
`;
