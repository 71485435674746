import React, { ChangeEvent, useState, useEffect } from 'react';
import { Pagination, Skeleton } from 'antd';
import api from '../../services/api';
import LayoutWithoutImage from '../../components/LayoutWithoutImage';
import SearchInput from '../../components/SearchInput';
import Table from './Table';

import { Container } from './styles';

interface UsersProps {
  email: string;
  cashback_balance: string;
  individual_user: {
    user_id: string;
    name: string;
    cpf: string;
  };
  bank: {
    name: string;
    account: string;
    agency: string;
    bank_code: {
      name: string;
    };
  };
}

const Users: React.FC = () => {
  const [usersList, setUsersList] = useState<UsersProps[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(10);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getUsers() {
      const { User } = JSON.parse(
        sessionStorage.getItem('ListzADM@UserAdmin') || '{}',
      );
      const headers = { Authorization: `Bearer ${User.token}` };
      const resp = await api.get(`users?limit=8&page=${page}`, {
        headers,
      });
      setUsersList(resp.data.results);
      setPage(resp.data.page);
      setTotal(resp.data.total);
      setLoading(true);
    }
    getUsers();
  }, []);

  async function handleUsersSearch(): Promise<any> {
    const { User } = JSON.parse(
      sessionStorage.getItem('ListzADM@UserAdmin') || '{}',
    );
    const headers = { Authorization: `Bearer ${User.token}` };
    const resp = await api.get(`users?name=${searchValue}&limit=8`, {
      headers,
    });
    setUsersList(resp.data.results);
  }

  function handleChangePage(e: any) {
    const { User } = JSON.parse(
      sessionStorage.getItem('ListzADM@UserAdmin') || '{}',
    );
    const headers = { Authorization: `Bearer ${User.token}` };
    api.get(`/users?page=${e}&limit=8`, { headers }).then((response) => {
      setUsersList(response.data.results);
    });
  }

  return (
    <LayoutWithoutImage>
      <Container>
        <SearchInput
          handleUsersSearch={handleUsersSearch}
          containerStyle={{ maxWidth: '472px' }}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <table>
          {loading ? (
            <>
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Email</th>
                  <th>CPF</th>
                  <th>Cashback</th>
                  <th>Banco</th>
                  <th>Agência</th>
                  <th>Conta</th>
                </tr>
              </thead>
              <tbody>
                {usersList.map((userList) => {
                  return <Table userList={userList} />;
                })}
              </tbody>
            </>
          ) : (
            <Skeleton className="skeleton" active />
          )}
        </table>
        <div className="pagination">
          <Pagination
            defaultCurrent={page}
            total={total}
            onChange={(e) => handleChangePage(e)}
          />
        </div>
      </Container>
    </LayoutWithoutImage>
  );
};

export default Users;
