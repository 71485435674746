import styled from 'styled-components';

interface FormProps {
  hasError: boolean;
}

export const Container = styled.div`
  margin-left: 184px;
  margin-right: 11.42vw;
  margin-top: 68px;
  margin-bottom: 24px;

  .inputSelect {
    display: flex;
    align-items: center;

    > div {
      margin-right: 20px;
    }

    .ant-select-selector {
      display: flex;
      align-items: center;
      box-shadow: 0px 1px 4px rgb(0 0 0 / 30%);
      background-color: #f5f5f5;
      border-radius: 16px;
      height: 57px;
    }

    .ant-select-selection-search {
      display: flex;
      align-items: center;
    }
  }

  h1 {
    font-size: 2.25rem;
    color: #2f78fe;
    font-family: Izmir-Bold;
    margin-bottom: 26px;
  }

  .loading {
    display: table;
    margin: 0 auto;
  }

  > table {
    overflow-y: scroll;
  }

  .pagination {
    display: table;
    margin: 10px auto;
  }

  table {
    display: block;
    margin-top: 29.5px;
    width: 100%;
    border-collapse: collapse;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
    border-radius: 24px;

    th,
    td {
      text-align: left;
      padding: 24px 16px;
    }

    thead {
      tr {
        color: #0000ff;
        font-family: Izmir-Bold;
        font-size: 1.12rem;
      }
    }

    tbody {
      td {
        white-space: nowrap;
        font-family: Izmir-Regular;
        font-size: 14px;
        button {
          background-color: #0000ff;
          color: #ffffff;
          font-family: Izmir-Bold;
          font-size: 13px;
          width: 88px;
          padding: 8.5px 0;
          border: none;
          border-radius: 24px;
          transition: background-color 0.1s;

          &:hover {
            background-color: #4039f7;
          }
        }
      }

      tr:nth-child(odd) {
        background-color: #f0f0f0;
      }
    }
  }

  .skeleton {
    width: 90%;
    margin: 0px 0px 0 50px;
  }

  @media (max-width: 1392px) {
    margin-left: 180px;
  }

  @media (max-width: 1230px) {
    margin-left: 160px;
  }

  @media (max-width: 993px) {
    margin: 80px auto;
    max-width: 600px;
  }

  @media (max-width: 935px) {
    margin: 80px auto;
    /* max-width: 400px; */
  }

  @media (max-width: 720px) {
    .inputSelect {
      flex-direction: column;
      gap: 20px;
    }
  }

  @media (max-width: 470px) {
    margin: 80px auto;
    max-width: 300px;
  }

  @media (max-width: 700px) {
    margin: 68px auto;
    /* max-width: 500px; */
  }

  @media (max-width: 540px) {
    max-width: 90vw;
  }
`;

export const Menu = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 50px;

  .active {
    background: #d5e0fc;
    color: #2f78fe;
    padding: 14px 14px 14px 14px;
    border-radius: 6px;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    width: 190px;
  }

  > p {
    color: #2f78fe;
    align-self: center;
    margin: 0;
    font-weight: bold;
    margin-right: 50px;
    cursor: pointer;
  }

  > p:hover {
    background: #d5e0fc;
    color: #2f78fe;
    padding: 14px 14px 14px 14px;
    border-radius: 6px;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
  }

  @media (max-width: 720px) {
    flex-direction: column;
    gap: 20px;
  }
`;

export const Form = styled.form<FormProps>`
  > div {
    > span {
      color: red;
      font-weight: 600;
    }
  }
`;

export const TextArea = styled.textarea`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  width: 100%;
  height: 64px;
  background-color: #f6f6f6;
  border-radius: 10px;
  margin: 20px 0px;
  padding: 0px 20px;
  padding-right: 48px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
  border: 0;
  width: 322px;
  height: 92px;
`;

export const CategoryTag = styled.div`
  background: #d5e0fc;
  color: #2f78fe;
  padding: 8px 8px 8px 8px;
  border-radius: 20px;
  width: 100px;
  text-align: center;
  font-weight: bold;
`;

export const Button = styled.button`
  background: #2f78fe;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  color: #fff;
  border: 0;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
`;
