/* eslint-disable react/jsx-curly-newline */

import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Switch } from 'antd';
import { toast } from 'react-toastify';
import api from '../../../services/api';

import { ColumnDescription } from './styles';

interface ListsProps {
  usersList: {
    id: string;
    name: string;
    description: string;
    products: [];
    category_id: number;
    is_highlight: boolean;
  };
}

const Table = ({ usersList }: ListsProps) => {
  const [highlight, setHighlight] = useState(usersList.is_highlight);
  const [descriptionDivHeight, setdescriptionDivHeight] = useState<number>(0);
  const [showDescription, setShowDescription] = useState<boolean>(false);

  const history = useHistory();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setHighlight(usersList.is_highlight);
  }, [usersList.is_highlight]);

  useEffect(() => {
    ref.current && setdescriptionDivHeight(ref?.current?.clientHeight);
  }, []);

  const listDescriptionWrapper = () => {
    console.log(descriptionDivHeight);

    return descriptionDivHeight > 60
      ? ' list__description-wrapper list__description-wrapper--large'
      : 'list__description-wrapper';
  };

  const colapseOrExpand = () => {
    return showDescription ? 'list__description--expand' : 'list__description';
  };

  function onChange(checked: boolean, id: string) {
    setHighlight(checked);

    const { User, UserInfo } = JSON.parse(
      sessionStorage.getItem('ListzADM@UserAdmin') || '{}',
    );

    const headers = { Authorization: `Bearer ${User.token}` };
    try {
      const res = api.put(
        `/lists/highlights/${id}`,
        {
          is_highlight: checked,
        },
        {
          headers,
        },
      );
      if (checked) {
        toast.success(`Lista visível`);
      } else {
        toast.error(`Lista ocultada`);
      }
    } catch (error) {
      toast.error(error.response?.data?.message || error.toString());
    }
  }

  return (
    <>
      <tr>
        <td>{usersList.name && usersList.name}</td>
        <ColumnDescription>
          <span className={listDescriptionWrapper()} ref={ref}>
            <span className={colapseOrExpand()}>
              {usersList.description && usersList.description}
            </span>

            <span
              className="list__see-more"
              onClick={() => setShowDescription(!showDescription)}
            >
              {!showDescription ? 'ver mais...' : 'ver menos...'}
            </span>
          </span>
        </ColumnDescription>
        <td>{usersList.products.length && usersList.products.length}</td>
        <td>
          <button
            type="button"
            onClick={() =>
              history.push({
                pathname: '/editar-listas',
                state: {
                  listId: usersList.id && usersList.id,
                  listCategory: usersList.category_id && usersList.category_id,
                },
              })
            }
          >
            Editar
          </button>
        </td>
        <td>
          <Switch
            checked={highlight}
            onClick={(value) => onChange(value, usersList.id)}
          />
        </td>
      </tr>
    </>
  );
};

export default Table;
