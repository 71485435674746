import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Skeleton } from 'antd';

import api from '../../services/api';

import ProfileLayout from './ProfileLayout';

import EditImg from '../../assets/profile/edit.png';

import { Container } from './styles';

interface storageInterface {
  email: string;
  avatar_url: string;
  individual_user: { name: string };
}

const Profile: React.FC = () => {
  const [userInfo, setUserInfo] = useState<storageInterface>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getUserInfo() {
      const { UserInfo, User } = JSON.parse(
        sessionStorage.getItem('ListzADM@UserAdmin') || '{}',
      );

      const headers = { Authorization: `Bearer ${User.token}` };
      const response = await api.get(`users/${UserInfo.userInfo.id}`, {
        headers,
      });
      setUserInfo(response.data);
      setLoading(true);
    }

    getUserInfo();
  }, []);

  return (
    <>
      <ProfileLayout>
        {loading ? (
          <Container>
            <h1>{userInfo?.individual_user.name}</h1>
            <div className="email-card">
              <p>
                <strong>Email:</strong> {userInfo?.email}
              </p>
              <Link to="/profile/edit-email">
                <img src={EditImg} alt="editar email" className="edit" />
              </Link>
            </div>
            <Link to="/profile/reset-password">Redefinir Senha</Link>
          </Container>
        ) : (
          <Skeleton className="skeleton" active />
        )}
      </ProfileLayout>
    </>
  );
};

export default Profile;
