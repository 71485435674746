import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  border: none;
  display: flex;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 16px;
  overflow: hidden;

  > div {
    width: 58.27px;
    background-color: #1511f9;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  input {
    width: 100%;
    height: 57px;
    background-color: transparent;
    border: none;
    font-size: 1.31rem;
    font-family: Izmir-Regular;
    margin-left: 24.5px;
  }
`;
