import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import LayoutWithImage from '../../../components/LayoutWithImage';

import CameraImg from '../../../assets/profile/camera.png';
import fallbackImg from '../../../assets/verticalTab/fallbackImg.jpg';

import { Container } from './styles';
import api from '../../../services/api';

const ProfileLayout: React.FC = ({ children }) => {
  const history = useHistory();
  const [userImg, setUserImg] = useState();

  useEffect(() => {
    async function getUserInfo() {
      const { UserInfo, User } = JSON.parse(
        sessionStorage.getItem('ListzADM@UserAdmin') || '{}',
      );

      const headers = { Authorization: `Bearer ${User.token}` };
      const response = await api.get(`users/${UserInfo.userInfo.id}`, {
        headers,
      });

      setUserImg(response.data.avatar_url);
    }

    getUserInfo();
  }, []);

  const { UserInfo, User } = JSON.parse(
    sessionStorage.getItem('ListzADM@UserAdmin') || '{}',
  );

  const paddingTop =
    history.location.pathname === '/profile' ? '67.5px' : '22.6px';

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;

    if (fileList) {
      const formData = new FormData();
      formData.append('avatar', fileList[0]);

      const response = await api.patch(
        `users/${UserInfo.userInfo.id}/avatar`,
        formData,
        {
          headers: {
            'Content-Type': `multipart/form-data;`,
            Authorization: `Bearer ${User.token}`,
          },
        },
      );

      setUserImg(response.data.avatar_url);
    }
  };

  return (
    <LayoutWithImage>
      <Container>
        <section style={{ paddingTop }}>
          <div className="image-card">
            <img
              src={userImg || fallbackImg}
              alt="minha foto"
              className="avatar"
              onError={(e: any) => {
                e.target.src = fallbackImg;
              }}
            />
            <div className="camera">
              <label htmlFor="avatar">
                <img src={CameraImg} alt="alterar foto" />
                <input
                  id="avatar"
                  accept="image/*"
                  type="file"
                  onChange={handleImageChange}
                />
              </label>
            </div>
          </div>
          {children}
        </section>
      </Container>
    </LayoutWithImage>
  );
};

export default ProfileLayout;
