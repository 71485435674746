import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: 23.82vh;
  left: 0;
  width: 129px;
  height: 64.77vh;
  background-color: #06e406;
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 35.5px 0 60.5px;

  .avatar {
    width: 71px;
    height: 71px;
    border-radius: 100%;
    object-fit: cover;
    object-position: top;
    border: 3px solid #0000ff;
  }

  a {
    position: relative;
    span {
      position: absolute;
      top: 2px;
      right: 1px;
      width: 10px;
      height: 10px;
      border-radius: 100%;
      background-color: #0000ff;
    }
  }

  @media (max-width: 900px) {
    display: none;
  }
`;
