import React from 'react';
import Header from '../Header';
import VerticalTab from '../VerticalTab';

import background1 from '../../assets/layoutWithImage/background1.png';
import background2 from '../../assets/layoutWithImage/background2.png';
import intersection from '../../assets/layoutWithImage/intersection.png';

import { Container } from './styles';

const LayoutWithImage: React.FC = ({ children }) => {
  return (
    <Container>
      <Header />
      <VerticalTab />
      <img
        className="background-left"
        src={background1}
        alt="imagem de fundo esquerda"
      />
      <img
        className="background-top"
        src={intersection}
        alt="imagem de fundo topo"
      />
      <img
        className="background-right"
        src={background2}
        alt="imagem de fundo direita"
      />
      <main>{children}</main>
    </Container>
  );
};

export default LayoutWithImage;
