import React, { useState, FormEvent } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import Input from '../../components/Input';
import Button from '../../components/Button';

import api from '../../services/api';

import logo from '../../assets/login/logo.png';
import background1 from '../../assets/signUp/background.png';
import intersection from '../../assets/signUp/intersection.png';
import lock from '../../assets/input/lock.png';

import { Container, Form } from './styles';

const PasswordChange: React.FC = () => {
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showLoadModal, setShowLoadModal] = useState(false);

  const history = useHistory();

  const token = new URLSearchParams(history.location.search).get('token');

  async function handleSubmit(
    event: FormEvent<HTMLFormElement>,
  ): Promise<void> {
    event.preventDefault();

    if (!password || !passwordConfirmation) {
      setErrorMessage('Insira a senha e confirmação de senha para continuar!');
    } else {
      setShowLoadModal(true);
      setErrorMessage('');

      const usr = {
        password,
        password_confirmation: passwordConfirmation,
        token,
      };

      try {
        await api.post('password/reset', usr);

        handleShow();
      } catch (error) {
        console.error('Ops! ocorreu um erro', error.response.data.message);
        setErrorMessage(error.response.data.message);
      }

      setShowLoadModal(false);
    }
  }

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <div>
          <Modal show={show} onHide={handleClose}>
            <div style={{ padding: 20, borderRadius: 50 }}>
              <h4>Senha resetada!</h4>
              <Button
                containerStyle={{ marginTop: '4vh' }}
                text="Voltar para login"
                type="submit"
                handleClick={() => history.push('/')}
              />
            </div>
          </Modal>
          <h1>Recuperar senha</h1>
          <Input
            containerStyle={{ marginTop: '3.6vh' }}
            icon={lock}
            alt="Password Icon"
            placeholder="Senha..."
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Input
            containerStyle={{ marginTop: '3.6vh' }}
            icon={lock}
            alt="Password Icon"
            placeholder="Confirmação de senha..."
            type="password"
            value={passwordConfirmation}
            onChange={(e) => setPasswordConfirmation(e.target.value)}
          />
          <Button
            containerStyle={{ marginTop: '4vh' }}
            text="Alterar senha"
            type="submit"
          />
          {!errorMessage && !showLoadModal ? (
            <span
              className="backLogin"
              onClick={() => history.push('/')}
              style={{ cursor: 'pointer' }}
            >
              Voltar para login
            </span>
          ) : (
            ''
          )}
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {errorMessage && <span>{errorMessage}</span>}
          {showLoadModal ? <p>Carregando...</p> : ''}
        </div>
      </Form>
      <img src={logo} alt="logo" />
      <img src={background1} alt="imagem de fundo direita" />
      <img src={intersection} alt="imagem de fundo topo" />
    </Container>
  );
};

export default PasswordChange;
