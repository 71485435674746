import * as Types from './types';

const INITIAL_STATE = {
  userInfo: '',
};

export default (state = INITIAL_STATE, { type, payload }: any) => {
  switch (type) {
    case Types.USER_INFO:
      return {
        userInfo: payload.user,
      };

    default:
      return state;
  }
};
