import React, { InputHTMLAttributes } from 'react';
import { AxiosResponse } from 'axios';

import SearchImg from '../../assets/searchInput/search.png';

import { Container } from './styles';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  containerStyle?: React.CSSProperties | undefined;
  handleUsersSearch?: () => Promise<AxiosResponse>;
}

const SearchInput: React.FC<IProps> = ({
  handleUsersSearch,
  containerStyle,
  ...rest
}) => {
  return (
    <Container style={containerStyle}>
      <div onClick={handleUsersSearch}>
        <img src={SearchImg} alt="search" />
      </div>
      <input type="text" placeholder="Buscar..." {...rest} />
    </Container>
  );
};

export default SearchInput;
