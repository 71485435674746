import React, { useState, useEffect, useMemo } from 'react';
import socketio from 'socket.io-client';

import { Link, useRouteMatch } from 'react-router-dom';
import { Pagination, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import api from '../../services/api';
import LayoutWithoutImage from '../../components/LayoutWithoutImage';
import SearchInput from '../../components/SearchInput';
import SolicitationsCard from './SolicitationsCard';
import ModalWrap from '../../components/ModalWrap';

import SearchImg from '../../assets/history/search.png';
import LeftArrowImg from '../../assets/history/left-arrow.png';
import RightArrowImg from '../../assets/history/right-arrow.png';

import { Container, CardWrapper, Modal } from './styles';

const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

interface RouteParam {
  userName: string;
}

interface UserData {
  id: string;
  value: string;
  agency: string;
  account: string;
  user_id: string;
  created_at: string;
  user: {
    email: string;
    individual_user?: {
      name: string;
      cpf: string;
    };
  };
  bank_code: {
    name: string;
  };
  status: {
    name: string;
    updated_at: string;
  };
}

const Solicitations: React.FC = () => {
  const [searchValue, setSearchValue] = useState('');
  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [user, setUser] = useState<UserData[]>([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(10);
  const [loading, setLoading] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [hideButton, setHideButton] = useState(false);

  const { params } = useRouteMatch<RouteParam>();

  const { UserInfo } = JSON.parse(
    sessionStorage.getItem('ListzADM@UserAdmin') || '{}',
  );

  const socket = useMemo(
    () =>
      socketio('https://api.listz.com.br', {
        query: {
          user_id: UserInfo.userInfo.id,
        },
      }),
    [UserInfo.userInfo.id],
  );

  useEffect(() => {
    socket.on('cashback-solicitations', (solicitation: any) => {
      setUser([solicitation, ...user]);
      console.log('solicitation', solicitation);
    });
  }, [socket, user]);

  useEffect(() => {
    async function getUsers() {
      const { User } = JSON.parse(
        sessionStorage.getItem('ListzADM@UserAdmin') || '{}',
      );
      const headers = { Authorization: `Bearer ${User.token}` };
      const resp = await api.get(
        `cashback-solicitations?name=${
          searchValue || params.userName === ':userName' ? '' : params.userName
        }&limit=10`,
        {
          headers,
        },
      );
      setUser(resp.data.results);
      setPage(resp.data.page);
      setTotal(resp.data.total);
      setLoading(true);
    }
    getUsers();
  }, [user, hideButton]);

  async function handleUsersSearch(): Promise<any> {
    const { User } = JSON.parse(
      sessionStorage.getItem('ListzADM@UserAdmin') || '{}',
    );
    const headers = { Authorization: `Bearer ${User.token}` };
    const resp = await api.get(`cashback-solicitations?name=${searchValue}`, {
      headers,
    });
    setUser(resp.data.results);
  }

  async function getUsersCashbacksByDate(): Promise<any> {
    if (!year || !month || !day) {
      setIsModal(true);
    } else {
      const { User } = JSON.parse(
        sessionStorage.getItem('ListzADM@UserAdmin') || '{}',
      );
      const headers = { Authorization: `Bearer ${User.token}` };
      const resp = await api.get(
        `cashback-solicitations?name=${searchValue}&date=${year}-${month}-${day}`,
        {
          headers,
        },
      );
      setUser(resp.data.results);
    }
  }

  function handleChangePage(e: any) {
    const { User } = JSON.parse(
      sessionStorage.getItem('ListzADM@UserAdmin') || '{}',
    );
    const headers = { Authorization: `Bearer ${User.token}` };
    api
      .get(`cashback-solicitations?name=${searchValue}&page=${e}&limit=10`, {
        headers,
      })
      .then(response => {
        setUser(response.data.results);
      });
  }

  function renderModal() {
    if (isModal) {
      return (
        <ModalWrap toggleModal={() => setIsModal(false)}>
          <Modal>
            <h2>Selecione Dia, mês e ano para pesquisar!</h2>
          </Modal>
        </ModalWrap>
      );
    }
  }

  return (
    <LayoutWithoutImage>
      <Container>
        <h1>Solicitações de Cashback</h1>

        <div className="input-direction">
          <div className="input-search">
            <SearchInput
              handleUsersSearch={handleUsersSearch}
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
              containerStyle={{ height: '54.5px' }}
            />
          </div>
          <div className="input-date">
            <input
              className="day-input"
              placeholder="Dia"
              value={day}
              onChange={e => setDay(e.target.value)}
            />
            <select
              name="month"
              id="month"
              onChange={e => setMonth(e.target.value)}
              required
            >
              <option selected disabled>
                Mês
              </option>
              <option value="01">Janeiro</option>
              <option value="02">Fevereiro</option>
              <option value="03">Março</option>
              <option value="04">Abril</option>
              <option value="05">Maio</option>
              <option value="06">Junho</option>
              <option value="07">Julho</option>
              <option value="08">Agosto</option>
              <option value="09">Setembro</option>
              <option value="10">Outubro</option>
              <option value="11">Novembro</option>
              <option value="12">Dezembro</option>
            </select>

            <select
              name="year"
              id="year"
              onChange={e => setYear(e.target.value)}
              required
            >
              <option selected disabled>
                Ano
              </option>
              <option value="2021">2021</option>
              <option value="2020">2020</option>
            </select>

            <button type="button" onClick={() => getUsersCashbacksByDate()}>
              <img src={SearchImg} alt="buscar" />
            </button>
          </div>
        </div>
        {loading ? (
          <>
            {user.map(result => (
              <CardWrapper>
                <SolicitationsCard
                  userInfo={result}
                  setHideButton={setHideButton}
                />
              </CardWrapper>
            ))}
          </>
        ) : (
          <div className="loading">
            <Spin indicator={antIcon} />
          </div>
        )}
        <Pagination
          defaultCurrent={page}
          total={total}
          onChange={e => handleChangePage(e)}
        />
        {/*
        <div className="pagination">
          <Link to="/history?page='1'" className="left-arrow">
            <img src={LeftArrowImg} alt="página à esquerda" />
          </Link>
          <Link to="/history?page='1'">1</Link>
          <Link to="/history?page='2'">2</Link>
          <Link to="/history?page='3'">3</Link>
          <Link to="/history?page='1'" className="right-arrow">
            <img src={RightArrowImg} alt="página à direita" />
          </Link>
        </div>
        */}
      </Container>
      {renderModal()}
    </LayoutWithoutImage>
  );
};

export default Solicitations;
