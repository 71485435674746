import React, { useEffect, useState, FormEvent } from 'react';
import { Pagination, Skeleton, Select } from 'antd';
import { useHistory } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import LayoutWithoutImage from '../../components/LayoutWithoutImage';
import Table from './Table';
import api from '../../services/api';
import SearchInput from '../../components/SearchInput';

import { Container, Menu } from './styles';

const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

interface Lists {
  id: string;
  name: string;
  description: string;
  products: [];
  category_id: number;
  is_highlight: boolean;
}

interface Categories {
  id: string;
  name: string;
}

const { Option } = Select;

const MyLists: React.FC = () => {
  const [usersList, setUsersList] = useState<Lists[]>([]);
  const [categories, setCategories] = useState<Categories[]>([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(10);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const history = useHistory();

  const { User } = JSON.parse(
    sessionStorage.getItem('ListzADM@UserAdmin') || '{}',
  );
  const headers = { Authorization: `Bearer ${User.token}` };

  useEffect(() => {
    async function getUsers() {
      const resp = await api.get(`/lists/?limit=8&page=${page}`, {
        headers,
      });
      setUsersList(resp.data.lists);
      setPage(resp.data.page);
      setTotal(resp.data.total);
      setLoading(true);
    }
    getUsers();
  }, []);

  useEffect(() => {
    async function getCategories() {
      const resp = await api.get(`/categories`, {
        headers,
      });
      setCategories(resp.data);
    }
    getCategories();
  }, []);

  function handleChangePage(e: any) {
    api.get(`/lists?page=${e}&limit=8`, { headers }).then((response) => {
      setUsersList(response.data.lists);
    });
  }

  async function handleUsersSearch(): Promise<any> {
    const resp = await api.get(`lists?name=${searchValue}&limit=8`, {
      headers,
    });
    setUsersList(resp.data.lists);
  }

  async function handleFilter(value: number) {
    const resp = await api.get(
      `/lists/admin?${
        value !== undefined
          ? `category_id=${value}&limit=8&page=${page}`
          : `limit=8&page=${page}`
      }`,
      {
        headers,
      },
    );
    setUsersList(resp.data.lists);
  }

  return (
    <LayoutWithoutImage>
      <Container>
        <Menu>
          <p onClick={() => history.push('/criar-listas')}>Criar lista</p>
          <p className="active">Listas criadas</p>
          <p onClick={() => history.push('/criar-categorias')}>
            Minhas categorias{' '}
          </p>
        </Menu>

        <div className="inputSelect">
          <SearchInput
            handleUsersSearch={handleUsersSearch}
            containerStyle={{ maxWidth: '472px' }}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />

          <Select
            showSearch
            style={{ width: 230 }}
            placeholder="Filtrar por categorias"
            optionFilterProp="children"
            onChange={handleFilter}
            allowClear
          >
            {categories.map((cat) => (
              <Option key={cat.id} value={cat.id}>
                {cat.name}
              </Option>
            ))}
          </Select>
        </div>

        <table>
          {loading ? (
            <>
              <thead>
                <tr>
                  <th>Nome da lista </th>
                  <th>Descrição</th>
                  <th>N de itens</th>
                  <th>Editar</th>
                  <th>Visibilidade</th>
                </tr>
              </thead>
              <tbody>
                {usersList &&
                  usersList.map((userList) => {
                    return <Table usersList={userList} />;
                  })}
              </tbody>
            </>
          ) : (
            <Skeleton className="skeleton" active />
          )}
        </table>
        <div style={{ display: 'table', margin: '20px auto 20px auto' }}>
          <Pagination
            defaultCurrent={page}
            total={total}
            showSizeChanger={false}
            onChange={(e) => handleChangePage(e)}
          />
        </div>
      </Container>
    </LayoutWithoutImage>
  );
};

export default MyLists;
